"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const core_1 = require("@material-ui/core");
const react_1 = require("react");
const inline_colors_1 = require("inline-colors");
function AuthorizationTooltip({ children, displayed, message = '', ...rest }) {
    return ((0, jsx_runtime_1.jsx)(core_1.Tooltip, { arrow: true, enterTouchDelay: 0, placement: "top", title: message ? ((0, jsx_runtime_1.jsx)("p", { style: {
                fontSize: '14px',
                color: inline_colors_1.INLINE_COLORS.whiteBase,
            }, children: message })) : displayed ? ((0, jsx_runtime_1.jsxs)("span", { style: { fontSize: '1.4em' }, children: ["Permission for this functionality is disabled.", (0, jsx_runtime_1.jsx)("br", {}), "Please contact your administrator to enable."] })) : (''), ...rest, children: children }));
}
exports.default = (0, react_1.memo)(AuthorizationTooltip);
