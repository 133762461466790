"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReturnURL = exports.needsFileUpload = exports.setupChangesMap = exports.createDataStructure = void 0;
const config_1 = require("config");
function createDataStructure(proposedChanges, currentData) {
    const result = [];
    currentData.forEach((store) => {
        let bankingChanged = false;
        let entityChanged = false;
        let anythingChanged = false;
        const curDataStore = store;
        const currentStoreNumber = store.adsStoreNumber;
        const proposedChangesForStore = proposedChanges.get(store.adsStoreNumber);
        if (proposedChangesForStore) {
            if (proposedChangesForStore.routingNumber !==
                store.metadata?.routingNumber ||
                proposedChangesForStore.accountNumber !== store.metadata?.accountNumber) {
                bankingChanged = true;
            }
            if (proposedChangesForStore.federalTaxId !== store.metadata?.federalTaxId ||
                proposedChangesForStore.legalBusinessName !==
                    store.metadata?.legalBusinessName ||
                proposedChangesForStore.federalTaxId !== store.metadata?.federalTaxId) {
                entityChanged = true;
            }
            if (proposedChangesForStore.practiceName !== store.metadata?.practiceName ||
                proposedChangesForStore.federalTaxId !== store.metadata?.federalTaxId ||
                entityChanged ||
                bankingChanged) {
                anythingChanged = true;
            }
            /**
             * @note Check the useEffect for "proposedChangeMap" in DocUpload to control if a file upload is required
             */
            if (bankingChanged || entityChanged || anythingChanged) {
                const store = proposedChanges.get(currentStoreNumber);
                const storeName = store ? store.practiceName : '';
                result.push({
                    storeNumber: currentStoreNumber,
                    storeName: storeName,
                    bankingChanged,
                    entityChanged,
                    ...needsFileUpload(bankingChanged, entityChanged, store?.files),
                    anythingChanged,
                    locationId: curDataStore?.locationId,
                });
            }
        }
    });
    return result;
}
exports.createDataStructure = createDataStructure;
const setupChangesMap = (curData, apiCall) => {
    return new Promise(async (resolve, reject) => {
        const proposedChangeMap = new Map();
        // For loop
        for (let i = 0; i < curData.length; i++) {
            const store = curData[i];
            try {
                const response = await apiCall(store.locationId);
                if (response.length) {
                    proposedChangeMap.set(store.adsStoreNumber, {
                        changeId: response[0].id,
                        practiceName: response[0].practiceName,
                        legalBusinessName: response[0].legalBusinessName,
                        legalEntityType: response[0].legalEntityType,
                        federalTaxId: response[0].federalTaxId,
                        routingNumber: response[0].routingNumber,
                        accountNumber: response[0].accountNumber,
                        status: response[0].status,
                        files: response[0].files,
                    });
                }
            }
            catch (e) {
                reject(e);
            }
            // End For loop
        }
        resolve(proposedChangeMap);
    });
};
exports.setupChangesMap = setupChangesMap;
function needsFileUpload(bankingChanged, entityChanged, existingFiles) {
    let existingBanking = false;
    let existingEntity = false;
    if (existingFiles && existingFiles.length > 0) {
        existingFiles.forEach((file) => {
            if (file?.attachmentContent === 'location_banking_proof') {
                existingBanking = true;
            }
            if (file?.attachmentContent === 'location_entity_proof') {
                existingEntity = true;
            }
        });
    }
    return {
        needsBankingFileUpload: bankingChanged && !existingBanking,
        needsEntityFileUpload: entityChanged && !existingEntity,
    };
}
exports.needsFileUpload = needsFileUpload;
function getReturnURL(pageTitle) {
    let goTo = {};
    if (pageTitle) {
        goTo = {
            pathname: config_1.LOCATION_MANAGEMENT_URL,
            state: { from: '/document-management' },
        };
    }
    else {
        goTo = {
            pathname: config_1.MERCHANT_PORTAL_URL,
            state: { from: '/new-deal' },
        };
    }
    return goTo;
}
exports.getReturnURL = getReturnURL;
