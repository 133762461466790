"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const components_1 = require("components");
const merchant_portal_components_1 = require("merchant-portal-components");
const reducer_1 = require("../reducer");
function CustomLink({ currentLocation }) {
    if (!currentLocation)
        return (0, jsx_runtime_1.jsx)(components_1.Spinner, {});
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(merchant_portal_components_1.MarketingToolbar, {}), (0, jsx_runtime_1.jsx)("div", { className: "qr-code-customizer", children: (0, jsx_runtime_1.jsxs)("div", { className: "qr-code-customizer__content", children: [(0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(StoreLinks, { storeNumber: currentLocation?.adsStoreNumber })] }) })] }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(CustomLink);
const StoreLinks = ({ storeNumber }) => {
    const tabletUrl = `${process.env.REACT_APP_CONSUMER_APP_URL}/practice-application/${storeNumber}`;
    const storeUrl = `${process.env.REACT_APP_CONSUMER_APP_URL}/credit-portal/store/${storeNumber}`;
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("div", { className: "custom-application-link", children: ["Custom Application Link: ", (0, jsx_runtime_1.jsx)("a", { href: storeUrl, children: storeUrl })] }), (0, jsx_runtime_1.jsxs)("div", { className: "custom-application-link", children: ["Tablet Link: ", (0, jsx_runtime_1.jsx)("a", { href: tabletUrl, children: tabletUrl })] })] }));
};
