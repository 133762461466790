"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
// Formats a Number with the specified fixed number of decimal places using
// the in-effect locale.
const formatFixedNumber = (value, fixed = 0) => {
    if ((0, lodash_1.isNil)(value))
        return 'N/A';
    return value.toLocaleString(undefined, {
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
    });
};
exports.default = formatFixedNumber;
