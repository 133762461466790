"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function safeStringToJson(jsonString) {
    try {
        return JSON.parse(jsonString);
    }
    catch (ex) {
        return null;
    }
}
exports.default = safeStringToJson;
