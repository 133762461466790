"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const reducer_1 = require("../reducer");
function ApplicationPending({ lenderReferenceId }) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "declined-container", children: (0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)("h1", { className: "first-title", children: "Application Pending" }), (0, jsx_runtime_1.jsx)("p", { className: "first-subtitle", children: "Thank you for your interest in the Alphaeon Credit Card." }), (0, jsx_runtime_1.jsx)("p", { children: "We're reviewing the information you provided and will contact you by mail within 10-14 business days regarding the status of your application." }), lenderReferenceId && (0, jsx_runtime_1.jsxs)("p", { children: [" Application ID: ", lenderReferenceId] })] }) }));
}
function mapStateToProps(state) {
    return {
        lenderReferenceId: reducer_1.selectors.lenderReferenceId(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(ApplicationPending);
