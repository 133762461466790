"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const capitalizeFirstLetterInEachWord = (str) => {
    if ((0, lodash_1.isEmpty)(str))
        return str;
    return str
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
exports.default = capitalizeFirstLetterInEachWord;
