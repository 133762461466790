"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const google_1 = require("@react-oauth/google");
const react_2 = require("react");
const react_router_dom_1 = require("react-router-dom");
const Logout = () => {
    const history = (0, react_router_dom_1.useHistory)();
    const handleBtn = () => {
        history.push('/portal/login');
    };
    (0, react_2.useEffect)(() => {
        (0, google_1.googleLogout)();
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { className: "login-container", children: (0, jsx_runtime_1.jsxs)(react_1.CCard, { className: "login-card", children: [(0, jsx_runtime_1.jsx)(react_1.CCardTitle, { as: "h5", children: "You are now signed out" }), (0, jsx_runtime_1.jsx)(react_1.CButton, { onClick: () => {
                        handleBtn();
                    }, color: "primary", children: "Return to Sign In Page" })] }) }));
};
exports.default = Logout;
