"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_to_print_1 = require("react-to-print");
const components_1 = require("components");
const merchant_portal_components_1 = require("merchant-portal-components");
function ReportDetailsContainer({ children, csvData, csvFilename, csvHeaders, reportRef, }) {
    const handlePrint = (0, react_to_print_1.useReactToPrint)({
        content: () => reportRef.current,
    });
    return ((0, jsx_runtime_1.jsxs)("div", { className: "report-detail", children: [(0, jsx_runtime_1.jsx)(components_1.SectionSeparator, {}), (0, jsx_runtime_1.jsxs)("div", { className: "report-detail__container m-t-30", children: [(0, jsx_runtime_1.jsx)(merchant_portal_components_1.ExportFiles, { csvHeaders: csvHeaders, csvData: csvData, handlePrint: handlePrint, fileName: csvFilename }), children] })] }));
}
exports.default = (0, react_1.memo)(ReportDetailsContainer);
