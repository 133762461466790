"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serverErrorLoggingMiddleware = void 0;
const redux_flash_1 = require("redux-flash");
const serverErrorLoggingMiddleware = ({ dispatch }) => (next) => (action) => {
    if ((action?.type?.includes('@@lp-redux-api') ||
        action?.type?.includes('merchantPortalApi') ||
        action?.type?.includes('consumerPortalApi')) &&
        (action?.payload?.status === 'failure' || action?.error?.message)) {
        const errorStatus = action.payload?.data?.status ?? action.payload?.status;
        const serverErrors = errorStatus >= 500 && errorStatus <= 599; //503 wont be passed here, will already be rerouted
        if (serverErrors) {
            dispatch((0, redux_flash_1.flashErrorMessage)(`Server Error: ${errorStatus}`));
        }
    }
    return next(action);
};
exports.serverErrorLoggingMiddleware = serverErrorLoggingMiddleware;
