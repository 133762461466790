"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
const getApplicationPath = (application) => {
    switch (application) {
        case config_1.APPLICATION.MERCHANT_PORTAL:
            return config_1.PATH.MERCHANT_PORTAL;
        case config_1.APPLICATION.CREDIT_PORTAL:
            return config_1.PATH.CREDIT_PORTAL;
        default:
            return config_1.PATH.ROOT;
    }
};
exports.default = getApplicationPath;
