"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
function AccessLoanMarketing() {
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", { className: "dashboard dashboard--header3", style: { textAlign: 'center' }, children: "Access loans will provide you an opportunity for increased revenue by providing customers another avenue beyond the Alphaeon Credit Card." }), (0, jsx_runtime_1.jsx)("div", { className: "center-align center button-block", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { type: "button", className: `button-warn-outline button-large`, onClick: () => {
                        window.location.href = 'https://www.myalphaeoncredit.com/access';
                    }, children: "See Additional Details" }) })] }));
}
exports.default = (0, react_1.memo)(AccessLoanMarketing);
