"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
function formatISODateStringAsUSMonthDayYear(dateString) {
    if ((0, lodash_1.isNil)(dateString) || dateString.trim() === '')
        return;
    return (0, date_fns_1.format)((0, date_fns_1.parseISO)(dateString), 'MM/dd/yyyy');
}
exports.default = formatISODateStringAsUSMonthDayYear;
