"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_final_form_1 = require("react-final-form");
const components_1 = require("components");
const TermsAgreement = ({ terms }) => {
    const groups = (0, lodash_1.groupBy)(terms, ({ checkboxGroup }) => checkboxGroup);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "stepper-checkbox pre-qualification", children: [(0, jsx_runtime_1.jsx)("div", { className: "section-header", children: "Terms & Agreement" }), (0, jsx_runtime_1.jsx)("p", { children: (0, jsx_runtime_1.jsx)("strong", { children: "Please review and acknowledge that you have received, read, and agreed to all of the following with American First Finance statements:" }) }), groups &&
                Object.values(groups).map((itm, idx) => {
                    return ((0, jsx_runtime_1.jsx)("div", { className: "checkbox-container", children: (0, jsx_runtime_1.jsx)("div", { className: "input-wrapper", children: (0, jsx_runtime_1.jsx)("label", { htmlFor: "termsAgreedTo1", children: (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(react_final_form_1.Field, { name: `termsAgreedTo${idx}`, label: false, component: components_1.InputCheckbox, type: "text", value: `termsAgreedTo${idx}` }), itm &&
                                            itm.map((itm2, idx2) => {
                                                return ((0, jsx_runtime_1.jsx)("span", { className: "term-link", children: (0, jsx_runtime_1.jsxs)(components_1.ExternalLink, { className: "agree-link", href: '#', onClick: (evt) => {
                                                            evt.preventDefault();
                                                            if (itm2 && itm2.link) {
                                                                open(itm2.link, '_blank');
                                                            }
                                                            else {
                                                                const newWin = open('url', '_blank');
                                                                newWin?.document.write(`${itm2.html && itm2.html}`);
                                                            }
                                                        }, target: "_blank", rel: "noreferrer", children: [itm2.title, itm.length !== idx2 + 1 ? ',' : null, ' '] }) }, `term-${idx2}`));
                                            })] }) }) }) }, `group-${idx}`));
                })] }));
};
exports.default = TermsAgreement;
