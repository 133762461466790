"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const internal_portal_components_1 = require("internal-portal-components");
function Layout({ children }) {
    const [showSidebar, setShowSidebar] = (0, react_1.useState)(true);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "internal-portal-layout", children: [(0, jsx_runtime_1.jsx)(internal_portal_components_1.Sidebar, { showSidebar: showSidebar }), (0, jsx_runtime_1.jsxs)("div", { className: "portal-dashboard", children: [(0, jsx_runtime_1.jsx)(internal_portal_components_1.Header, { showSidebar: showSidebar, setShowSidebar: setShowSidebar }), (0, jsx_runtime_1.jsx)("div", { className: "portal-main", children: children })] }), (0, jsx_runtime_1.jsx)("div", { id: "modal-root" })] }));
}
exports.default = Layout;
