"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function formatEmailAddress(email) {
    if ((0, lodash_1.isNil)(email))
        return '';
    if (!email.includes('@'))
        return email;
    const [user] = email.split('@');
    return `${user}@`;
}
exports.default = formatEmailAddress;
