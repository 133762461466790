"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function validateEIN(value) {
    if (!value) {
        return 'Required';
    }
    if (!/^\d{2}-\d{7}$/.test(value)) {
        return 'Must be in the format XX-XXXXXXX.';
    }
}
exports.default = validateEIN;
