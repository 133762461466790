"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const config_1 = require("config");
function StartApplicationForm({ handleSubmit, photoIdProvided, disclosureProvided, }) {
    const showSubmitButton = photoIdProvided && disclosureProvided;
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form m-t-50", children: (0, jsx_runtime_1.jsxs)("form", { noValidate: true, onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsxs)("div", { className: "", children: [(0, jsx_runtime_1.jsxs)("div", { className: "checkbox-container", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "disclosureProvided", component: lp_components_1.Checkbox, label: false }), "I confirm that I have provided the disclosures/agreement to the customer"] }), (0, jsx_runtime_1.jsxs)("div", { className: "checkbox-container", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "photoIdProvided", component: lp_components_1.Checkbox, label: false }), "I confirm that I have seen the applicant\u2019s valid photo ID prior to beginning the application"] })] }), (0, jsx_runtime_1.jsxs)("div", { className: "start-application__form--actions m-t-50 m-b-50", children: [(0, jsx_runtime_1.jsx)("div", { className: "submit", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { disabled: !showSubmitButton, className: `button-warn ${!showSubmitButton ? `warn-disabled` : ``}`, children: "BEGIN REQUEST FOR CREDIT" }) }), (0, jsx_runtime_1.jsx)("div", { className: "cancel", children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: config_1.MERCHANT_PORTAL_URL, children: "CANCEL" }) })] })] }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'startApplicationForm',
    constraints: {},
    submitFilters: {
        reject: ['disclosureProvided', 'photoIdProvided'],
    },
}), (0, redux_form_1.formValues)({
    disclosureProvided: 'disclosureProvided',
    photoIdProvided: 'photoIdProvided',
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(StartApplicationForm);
