"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function optionsFromLocations(locations) {
    return locations.map(({ adsStoreNumber, name }, index) => ({
        label: `${name}, ${adsStoreNumber}`,
        storeNumber: adsStoreNumber,
        value: index,
    }));
}
exports.default = optionsFromLocations;
