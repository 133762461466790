"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
function operationFromTransaction(status, transactionType, refundTransaction, voidTransaction, consumerCreditInstrumentType) {
    const isVoidableState = config_1.VOIDABLE_STATES.includes(status);
    const isNotRefund = transactionType != config_1.TRANSACTION_TYPE.REFUND;
    const isNotInstallment = consumerCreditInstrumentType !== 'installment';
    if (isVoidableState && (isNotRefund || isNotInstallment)) {
        return {
            operationLabel: 'VOID',
            operation: voidTransaction,
            requiredPermission: config_1.PERMISSION.PROCESS_VOID,
        };
    }
    if (config_1.REFUNDABLE_STATES.includes(status)) {
        // Only sales can be refunded; refunds cannot be refunded!
        if (transactionType === config_1.TRANSACTION_TYPE.SALE) {
            return {
                operationLabel: 'REFUND',
                operation: refundTransaction,
                requiredPermission: config_1.PERMISSION.PROCESS_REFUND,
            };
        }
    }
    // The transaction is in a state that cannot be operated on...
    return { operationLabel: null, operation: null, requiredPermission: null };
}
exports.default = operationFromTransaction;
