"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const react_1 = require("react");
function useInterval(callback, { interval, invokeFirst = false }) {
    (0, react_1.useEffect)(() => {
        if (invokeFirst)
            callback();
        if (!(0, lodash_1.isNil)(interval) && interval > 0) {
            const intervalId = setInterval(callback, interval);
            return () => clearInterval(intervalId);
        }
    }, [callback, interval]);
}
exports.default = useInterval;
