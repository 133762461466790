"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
function validDateOfBirth(dateOfBirth) {
    return (
    // Date of birth must be after 1900
    dateOfBirth &&
        (0, date_fns_1.getYear)(dateOfBirth) >= 1900 &&
        // Applicant must be 18 years or older
        (0, date_fns_1.differenceInYears)(new Date(), dateOfBirth) >= 18);
}
exports.default = validDateOfBirth;
