"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaskedInputWithToolTip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const components_1 = require("components");
const MaskedInputWithToolTip = (props) => {
    const [focus, setFocus] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(lp_components_1.MaskedInput, { onFocus: () => {
                    setFocus(true);
                }, ...props }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: props.input.name, focused: focus, children: (val) => {
                    if ((val || focus) && props.tooltips) {
                        return (0, jsx_runtime_1.jsx)(props.tooltips, {});
                    }
                    return null;
                } })] }));
};
exports.MaskedInputWithToolTip = MaskedInputWithToolTip;
exports.default = { MaskedInputWithToolTip: exports.MaskedInputWithToolTip };
