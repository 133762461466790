"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function formatErrorMessage(error_message) {
    if ((0, lodash_1.isNil)(error_message))
        return error_message;
    const mapping = {
        purchase_amount: 'Purchase Amount',
    };
    Object.keys(mapping).forEach((key) => {
        error_message = error_message.replaceAll(key, mapping[key]);
    });
    return error_message;
}
exports.default = formatErrorMessage;
