"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const config_1 = require("config");
function UserFilterForm({ onSubmit }) {
    return ((0, jsx_runtime_1.jsx)("div", { className: "start-application__form", children: (0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: onSubmit, children: (0, jsx_runtime_1.jsxs)("div", { className: "activity-filter", children: [(0, jsx_runtime_1.jsx)("div", { className: "activity-filter--item search", children: (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "searchQuery", component: lp_components_1.Input, label: false, autoComplete: "off", placeholder: "Find a User or Email" }) }), (0, jsx_runtime_1.jsxs)("div", { className: "checkbox-group activity-filter--item", children: [(0, jsx_runtime_1.jsx)("label", { className: "label", htmlFor: "userStatus", children: "User Status" }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { label: false, component: lp_components_1.CheckboxGroup, name: "userStatus", options: config_1.USER_ACTIVE_STATUS })] })] }) }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'userFilterForm',
    constraints: {},
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(UserFilterForm);
