"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
function determinePrequalificationError(error) {
    if (error?.response?.errorCode === 'prequalify_success_without_offers') {
        return {
            pathname: config_1.PRE_QUALIFICATION_ACCEPT_DECLINED_URL,
        };
    }
}
exports.default = determinePrequalificationError;
