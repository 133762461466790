"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function createDynamicColumns(data) {
    const index = data[0];
    const keysArray = Object.keys(index);
    function convertKeyNames(key) {
        const stringArr = key.split('');
        const result = [key[0].toUpperCase()];
        for (let i = 1; i < stringArr.length; i++) {
            if (stringArr[i] === stringArr[i].toUpperCase()) {
                result.push(' ', stringArr[i]);
            }
            else {
                result.push(stringArr[i]);
            }
        }
        return result.join('');
    }
    const columns = keysArray.map((key) => {
        return {
            header: convertKeyNames(key),
            accessorKey: key,
            filterFn: 'filterAsString',
        };
    });
    return columns;
}
exports.default = createDynamicColumns;
