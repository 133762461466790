"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("components");
const utils_1 = require("utils");
const FIELD_TO_TEXT = {
    minPurchaseAmount: 'Minimum Purchase Amount',
    dashboardEnabled: 'Dashboard / Calulator Display',
    payMyProviderEnabled: 'Pay my provider',
};
function ProgramManagementConfirmationModal({ locPlanDiff, accessLoanPlanDiff, onClose, updatingLocationPlans, onSubmitChanges, }) {
    const accessLoanHtml = () => {
        let html = (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
        accessLoanPlanDiff.forEach((accessLoanChange) => {
            let details = (0, utils_1.safeStringToJson)(accessLoanChange.plan.planDetails);
            if (details == undefined) {
                details = {};
            }
            html = ((0, jsx_runtime_1.jsxs)("li", { children: ["Access Loan", ' ', (0, jsx_runtime_1.jsxs)("b", { children: [accessLoanChange.plan.name, " ", details['additional_name'], " Enabled"] })] }, accessLoanChange.id));
        });
        return html;
    };
    return ((0, jsx_runtime_1.jsxs)(lp_components_1.Modal, { onClose: onClose, className: "modal-inner program-management-confirm-modal", children: [(0, jsx_runtime_1.jsxs)("div", { className: "program-management-confirm-modal__container", children: [(0, jsx_runtime_1.jsx)("div", { className: "title dashboard--header", children: "Summary of Changes" }), (0, jsx_runtime_1.jsxs)("div", { className: "details", children: [(0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("ul", { children: [locPlanDiff.length > 0 &&
                                            locPlanDiff.map((locChange) => {
                                                let to = locChange.to;
                                                let from = locChange.from;
                                                if (locChange.field.includes('Amount')) {
                                                    from = '$' + locChange.from.toString();
                                                    to = '$' + locChange.to.toString();
                                                }
                                                const fieldName = locChange.field;
                                                let changeHtml = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [locChange.name, " changed from", ' ', (0, jsx_runtime_1.jsxs)("b", { children: [from.toString(), " to ", to.toString(), " for", ' ', FIELD_TO_TEXT[fieldName]] })] }));
                                                if (typeof to == 'boolean') {
                                                    const changeVerb = to ? 'enabled' : 'disabled';
                                                    changeHtml = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [locChange.name, ' ', (0, jsx_runtime_1.jsxs)("b", { children: [' ', changeVerb, " for ", FIELD_TO_TEXT[fieldName]] })] }));
                                                }
                                                return (0, jsx_runtime_1.jsx)("li", { children: changeHtml }, locChange.id);
                                            }), accessLoanPlanDiff.length > 0 && accessLoanHtml()] }) }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {})] }), (0, jsx_runtime_1.jsxs)("div", { className: "button-block", children: [(0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-warn-outline", onClick: onSubmitChanges, children: "Confirm" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: '#', onClick: onClose, children: "CANCEL" })] })] }), (0, jsx_runtime_1.jsx)("div", { className: "mt-5 spinner", children: updatingLocationPlans && (0, jsx_runtime_1.jsx)(components_1.Spinner, {}) })] }));
}
exports.default = (0, react_1.memo)(ProgramManagementConfirmationModal);
