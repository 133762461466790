"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApplicationBuildId = void 0;
const utils_1 = require("utils");
const globalActions_1 = require("./globalActions");
const getApplicationBuildId = () => {
    return async function (dispatch) {
        const applicationBuildId = await (0, utils_1.fetchApplicationBuildId)();
        dispatch((0, globalActions_1.recordApplicationBuildId)(applicationBuildId));
    };
};
exports.getApplicationBuildId = getApplicationBuildId;
