"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("../components");
function VerifyStoreInformation({}) {
    const location = (0, react_router_dom_1.useLocation)();
    const locationData = location.state ? location.state.data : null;
    const [markedCompleteOrSkipped, setMarkedCompleteOrSkipped] = (0, react_1.useState)({});
    const unsignedLocationData = (0, react_1.useMemo)(() => {
        return locationData?.filter((location) => {
            return (location.needsToSignNewDealTerms &&
                location.isCurrentLocationManagerSigner);
        });
    }, [locationData]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "verify-store-information-title", children: [(0, jsx_runtime_1.jsx)(components_1.VerifyStoreInfoHeader, {}), (0, jsx_runtime_1.jsx)("div", { className: "verify-store-information", children: unsignedLocationData &&
                    unsignedLocationData.map((data, index) => ((0, jsx_runtime_1.jsx)(components_1.StoreInformationContainer, { setMarkedCompleteOrSkipped: setMarkedCompleteOrSkipped, enrollmentData: data, index: index, singleDisplay: unsignedLocationData.length === 1, keepHeader: true, isNewDeal: true }, index))) }), unsignedLocationData && ((0, jsx_runtime_1.jsx)(components_1.AcceptTermsAndSignShell, { locationData: unsignedLocationData, markedCompleteOrSkipped: markedCompleteOrSkipped }))] }));
}
exports.default = VerifyStoreInformation;
