"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const auth0_react_1 = require("@auth0/auth0-react");
const components_1 = require("components");
function AuthenticatedRoutes({ children }) {
    return children;
}
exports.default = (0, auth0_react_1.withAuthenticationRequired)(AuthenticatedRoutes, {
    onRedirecting: () => (0, jsx_runtime_1.jsx)(components_1.Spinner, { centerPage: true }),
});
