"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const framer_motion_1 = require("framer-motion");
const config_1 = require("config");
const AppWelcome = () => {
    // Vars
    const ANIMX_SPEED_BOXES = 0.15;
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "dashboard-welcome-msg", initial: { opacity: 0, scale: 0.5 }, animate: {
                opacity: 1,
                scale: 1,
            }, transition: {
                delay: Number((ANIMX_SPEED_BOXES * (config_1.DASHBOARD_ACTIONS.length + 1)) / 2),
                duration: 0.5,
            }, children: (0, jsx_runtime_1.jsx)("div", { className: "dashboard--header", children: "Welcome to your Dashboard" }) }) }));
};
exports.default = AppWelcome;
