"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function checkForNull(value) {
    if ((0, lodash_1.isNil)(value)) {
        return;
    }
    if (!(0, lodash_1.isEmpty)(value) && value.toLowerCase() !== 'null') {
        return value;
    }
    return;
}
exports.default = checkForNull;
