"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockLocations = void 0;
const faker_1 = require("@faker-js/faker");
const mockLocations = () => {
    return new Array(100).fill(null).map(() => {
        return {
            locationId: Number(faker_1.faker.number.bigInt({ min: 1n, max: 100n })),
            name: String(faker_1.faker.company.name()),
            adsStoreNumber: Number(faker_1.faker.number.bigInt({ min: 300000n, max: 900000n })),
        };
    });
};
exports.mockLocations = mockLocations;
