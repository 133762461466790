"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_BANK_NAME = exports.GET_PROPOSED_ENROLLMENT_DATA_CHANGES = void 0;
exports.GET_PROPOSED_ENROLLMENT_DATA_CHANGES = [
    {
        id: 183,
        bank_name: 'Chase',
        practice_name: 'Core Surgery Center Inc',
        legal_business_name: 'Core Surgery Center Inc',
        legal_entity_type: 'Corporation',
        federal_tax_id: '423424242',
        routing_number: '123456789',
        account_number: '987654321',
        status: 'created',
        comment: null,
        files: [],
    },
];
exports.GET_BANK_NAME = { name: 'PH Bank', details: {} };
