"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePhoneNumberWithAlt = exports.validatePhoneNumber = exports.validateSSN = exports.validateEmail = void 0;
const _1 = require(".");
/**
 * @name validateEmail
 * @param value
 * @returns
 */
const validateEmail = (value) => {
    // This is very basic and does not check for domain e.g. (.com)
    const pattern = /^\S+@\S+$/; // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s01.html
    if (value && value.match(pattern)) {
        return;
    }
    else {
        return 'Invalid email address';
    }
};
exports.validateEmail = validateEmail;
/**
 * @name validateSSN
 * @param value
 * @returns
 */
const validateSSN = (value) => {
    const pattern = /^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/; // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s12.html
    if (value && value.match(pattern)) {
        return;
    }
    else {
        return 'Invalid social security number';
    }
};
exports.validateSSN = validateSSN;
/**
 * @name validatePhoneNumber
 * @param value
 * @returns
 */
const validatePhoneNumber = (value) => {
    if (value && value.trim() === '') {
        return 'Please enter a phone number'; // Check empty strings (spaces)
    }
    const valueFmt = (0, _1.removeNonDigits)(value); // Remove all non-digits
    if (valueFmt && valueFmt.trim() === '') {
        return 'Please enter a phone number'; // Recheck for blanks and trim
    }
    if (valueFmt === '') {
        return 'Please enter a phone number'; // Recheck with just blank
    }
    // Check North American #'s
    const pattern = /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/; // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
    if (valueFmt && !valueFmt.match(pattern))
        return 'Invalid phone number';
    // Basic check for Area Codes and common abuse numbers
    if (!checkRealPhoneNumber(valueFmt)) {
        return 'Phone Number Is Not Valid';
    }
    // Passes
    return;
};
exports.validatePhoneNumber = validatePhoneNumber;
/**
 * @name validatePhoneNumberWithAlt
 * @param value
 * @param allValues
 * @param _
 * @param name
 * @returns
 */
const validatePhoneNumberWithAlt = (value, allValues, _, name) => {
    const { homeNumberCheck } = allValues;
    // Check North American #'s
    const pattern = /^\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/; // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s02.html
    if (value && !value.match(pattern))
        return 'Invalid phone number';
    if (homeNumberCheck && name === 'alternatePhone')
        return "Home Number can't be blank";
    if (!homeNumberCheck && name === 'primaryPhone')
        return "Mobile Number can't be blank";
    // Passes
    return;
};
exports.validatePhoneNumberWithAlt = validatePhoneNumberWithAlt;
/**
 * @name LIST_AREA_CODES
 * @description https://www.nationalnanpa.com/enas/geoAreaCodeNumberReport.do
 */
const LIST_AREA_CODES = [
    201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 212, 213, 214, 215, 216,
    217, 218, 219, 220, 223, 224, 225, 226, 227, 228, 229, 231, 234, 236, 239,
    240, 242, 246, 248, 249, 250, 251, 252, 253, 254, 256, 260, 262, 263, 264,
    267, 268, 269, 270, 272, 274, 276, 279, 281, 283, 284, 289, 301, 302, 303,
    304, 305, 306, 307, 308, 309, 310, 312, 313, 314, 315, 316, 317, 318, 319,
    320, 321, 323, 325, 326, 329, 330, 331, 332, 334, 336, 337, 339, 340, 341,
    343, 345, 346, 347, 350, 351, 352, 353, 354, 360, 361, 363, 364, 365, 367,
    368, 369, 380, 382, 385, 386, 401, 402, 403, 404, 405, 406, 407, 408, 409,
    410, 412, 413, 414, 415, 416, 417, 418, 419, 423, 424, 425, 428, 430, 431,
    432, 434, 435, 437, 438, 440, 441, 442, 443, 445, 447, 448, 450, 458, 463,
    464, 468, 469, 470, 472, 473, 474, 475, 478, 479, 480, 484, 501, 502, 503,
    504, 505, 506, 507, 508, 509, 510, 512, 513, 514, 515, 516, 517, 518, 519,
    520, 530, 531, 534, 539, 540, 541, 548, 551, 557, 559, 561, 562, 563, 564,
    567, 570, 571, 572, 573, 574, 575, 579, 580, 581, 582, 584, 585, 586, 587,
    601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 612, 613, 614, 615, 616,
    617, 618, 619, 620, 623, 624, 626, 628, 629, 630, 631, 636, 639, 640, 641,
    645, 646, 647, 649, 650, 651, 656, 657, 658, 659, 660, 661, 662, 664, 667,
    669, 670, 671, 672, 678, 680, 681, 682, 683, 684, 689, 701, 702, 703, 704,
    705, 706, 707, 708, 709, 712, 713, 714, 715, 716, 717, 718, 719, 720, 721,
    724, 725, 726, 727, 728, 730, 731, 732, 734, 737, 740, 742, 743, 747, 753,
    754, 757, 758, 760, 762, 763, 765, 767, 769, 770, 771, 772, 773, 774, 775,
    778, 779, 780, 781, 782, 784, 785, 786, 787, 801, 802, 803, 804, 805, 806,
    807, 808, 809, 810, 812, 813, 814, 815, 816, 817, 818, 819, 820, 825, 826,
    828, 829, 830, 831, 832, 835, 838, 839, 840, 843, 845, 847, 848, 849, 850,
    854, 856, 857, 858, 859, 860, 861, 862, 863, 864, 865, 867, 868, 869, 870,
    872, 873, 876, 878, 901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 912,
    913, 914, 915, 916, 917, 918, 919, 920, 925, 928, 929, 930, 931, 934, 936,
    937, 938, 939, 940, 941, 943, 945, 947, 948, 949, 951, 952, 954, 956, 959,
    970, 971, 972, 973, 975, 978, 979, 980, 983, 984, 985, 986, 989,
];
/**
 * @name checkRealPhoneNumber
 * @description This is taken and adapted from this old small lib
 * @returns boolean
 */
const checkRealPhoneNumber = (phone) => {
    // size must be 10
    if (phone.length != 10) {
        return false;
    }
    // split it by parts
    const area_code = Number(phone.substring(0, 3));
    const exchange_code = phone.substring(3, 6);
    const station_code = phone.substring(6, 10);
    if (LIST_AREA_CODES.indexOf(area_code) < 0) {
        return false;
    }
    // exchange code sanity check: cannot end in two 1's
    if (exchange_code.substring(1) == '11') {
        return false;
    }
    // this is a funky exchange code
    if (exchange_code == '555') {
        // directory assistance and national use
        if (station_code == '1212' || station_code == '4334') {
            return false;
        }
        // fictitious numbers
        if (station_code[0] == '0') {
            const station_code_number = Number(station_code);
            if (station_code_number >= 100 && station_code_number <= 199) {
                return false;
            }
        }
    }
    // eeeeyy
    return true;
};
