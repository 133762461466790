"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const api_1 = require("../services/api");
function isFetchError(e) {
    return e instanceof TypeError && e.message === 'Failed to fetch';
}
const API_URL = api_1.api.defaults.root;
function ServerStatusOverlay() {
    const [showOverlay, setShowOverlay] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        // Ping server on mount
        if (API_URL) {
            api_1.api.get('/').catch((e) => {
                if (isFetchError(e))
                    setShowOverlay(true);
            });
        }
    }, []);
    if (process.env.REACT_APP_HIDE_SERVER_STATUS_OVERLAY || !showOverlay)
        return null;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "server-status-overlay", children: ["Warning: there is no server running at your configured API url: ", API_URL] }));
}
exports.default = (0, react_1.memo)(ServerStatusOverlay);
