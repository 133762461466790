"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const apiActions = __importStar(require("api-actions"));
const config_1 = require("config");
const utils_1 = require("utils");
const forms_1 = require("../forms");
const reducer_1 = require("../reducer");
function SupplementalFunding({ createNewCreditApplication, currentLocation, flashSuccessMessage, sendExternalReferencesCommunication, }) {
    const { state } = (0, react_router_dom_1.useLocation)();
    const history = (0, react_router_dom_1.useHistory)();
    if ((0, lodash_1.isNil)(state))
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: config_1.ACCOUNT_LOOKUP_URL });
    const { accountDetail } = state;
    const { address, email, name, primaryPhone: sms } = accountDetail;
    const applicationCallbackUrl = (0, utils_1.makeQueryPath)((0, config_1.makePath)(config_1.CONSUMER_APP_URL, config_1.APPLICANTS_URL), {
        action: config_1.ACTION.NEW_ACCESS_LOAN,
    });
    const handleSubmit = (communicationPreference) => {
        const { userReferenceId } = communicationPreference;
        const newCreditApplication = {
            location_id: currentLocation.locationId,
            firstName: name.firstName,
            lastName: name.lastName,
            middleInitial: name.middleInitial,
            ssn: accountDetail.ssn,
            ssnLast_4: accountDetail.ssnLast4,
            dateOfBirth: accountDetail.dateOfBirth,
            annualIncome: accountDetail.annualIncome,
            email,
            street1: address.street1,
            street2: address.street2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            country: address.country,
            primaryPhone: accountDetail.primaryPhone,
            supplementedConsumerCreditInstrumentId: accountDetail.consumerCreditInstrumentId,
            userReferenceId,
        };
        if (accountDetail.alternatePhone) {
            newCreditApplication.alternatePhone = accountDetail.alternatePhone;
        }
        const normalizedCreditApplication = (0, lodash_1.omitBy)(newCreditApplication, lodash_1.isNil);
        return createNewCreditApplication(normalizedCreditApplication).then((creditApplictionResponse) => {
            const { creditApplication: { applicationId }, } = creditApplictionResponse;
            const referenceData = {
                ...(0, lodash_1.omit)(communicationPreference, 'userReferenceId'),
                applicationCallbackUrl,
            };
            return sendExternalReferencesCommunication(applicationId, referenceData);
        });
    };
    const handleSubmitSuccess = () => {
        flashSuccessMessage('Successfully sent prequalification form link to customer.');
        history.push(config_1.MERCHANT_PORTAL_URL);
    };
    const handleCancel = () => {
        history.push(config_1.MERCHANT_PORTAL_URL);
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: "declined-container", children: (0, jsx_runtime_1.jsxs)("div", { className: "content", children: [(0, jsx_runtime_1.jsx)("h1", { className: "first-title", style: { lineHeight: '38px', textAlign: 'center' }, children: "Access Loan Prequalification" }), (0, jsx_runtime_1.jsx)("p", { children: "Supplemental funding allows you to get additional funds in order to meet the procedure's transaction amount." }), (0, jsx_runtime_1.jsx)("p", { children: "In order to complete this request, the patient must complete an Access Loan prequalification. Please select the Patient's desired method of receiving the prequalification form." }), (0, jsx_runtime_1.jsx)(forms_1.ContactPatientForm, { withReference: true, initialValues: (0, utils_1.contactFormInitialValues)({ email, sms }), onSubmit: handleSubmit, handleCancel: handleCancel, onSubmitSuccess: handleSubmitSuccess, onSubmitFail: utils_1.flashSubmitFailure })] }) }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
    };
}
const mapDispatchToProps = {
    flashSuccessMessage: redux_flash_1.flashSuccessMessage,
};
const mapApiAuthToProps = {
    createNewCreditApplication: apiActions.createNewCreditApplication,
    sendExternalReferencesCommunication: apiActions.sendExternalReferencesCommunication,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(SupplementalFunding);
