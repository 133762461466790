"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const utils_1 = require("utils");
const PrintReceiptButton = ({ transactionId, status, ssnLast4, callback, productType, flashErrorMessage, getSalesReceipt, getSalesReceiptSigned, createReceipt, signReceiptV2, }) => {
    // State
    const [loadingSignReceipt, setLoadingSignReceipt] = (0, react_1.useState)(false);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const [confirm, setConfirm] = (0, react_1.useState)(false);
    const [receiptSuccess, setReceiptSuccess] = (0, react_1.useState)(false);
    const [printStatus, setPrintStatus] = (0, react_1.useState)(status);
    const handlePrintModal = (transactionId) => {
        if (transactionId) {
            setIsOpen(true);
        }
    };
    // Print Normal
    const handlePrint = (transactionId) => {
        setLoading(true);
        getSalesReceipt(transactionId)
            .then((pdfWindowURL) => {
            (0, utils_1.windowOpenAndPrint)(pdfWindowURL); // Print
        })
            .catch(() => {
            flashErrorMessage('PDF download and print failed');
        })
            .finally(() => setLoading(false));
    };
    // Print Signed Version
    const handlePrintSigned = (transactionId) => {
        setLoading(true);
        getSalesReceiptSigned(transactionId)
            .then((pdfWindowURL) => {
            (0, utils_1.windowOpenAndPrint)(pdfWindowURL); // Print
        })
            .catch(() => {
            flashErrorMessage('PDF download and print failed');
        })
            .finally(() => {
            setLoading(false);
            if (callback) {
                callback(); // Reload page/data
            }
        });
    };
    const handleCreateAndSignReceipt = async (transactionId) => {
        setLoadingSignReceipt(true);
        // Display PDF
        handlePrintSigned(transactionId); // New v2 API
        const communicationMethod = 'print';
        const respCreateReceipt = await createReceipt(transactionId, communicationMethod); // Create Receipts
        const signReceiptRequest = {
            transactionId: transactionId,
            receiptId: Number(respCreateReceipt.receiptId),
            ssnLastFour: ssnLast4,
        };
        await signReceiptV2(signReceiptRequest); // Sign Receipt v2
        setLoadingSignReceipt(false);
        setReceiptSuccess(true); // Display Success Msg
        setPrintStatus('signed');
    };
    const onRequestClose = () => {
        setIsOpen(false);
        setReceiptSuccess(false);
        setConfirm(false);
        if (callback) {
            callback(); // Reload page/data
        }
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-primary button-warn print-receipt-button", invalid: loading, submitting: loading, onClick: () => {
                    if (printStatus === 'pending_signature' &&
                        productType != config_1.PRODUCT_TYPE_MAP.installment) {
                        handlePrintModal(transactionId); // Create and Sign (MC-1394)
                    }
                    else {
                        handlePrint(transactionId);
                    }
                }, children: "PRINT RECEIPT" }), (0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, onRequestClose: onRequestClose, children: receiptSuccess ? ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("h3", { children: "Receipt Now Available" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)("div", { className: "attention-paragraph", children: [(0, jsx_runtime_1.jsx)("span", { className: "icon-text-attention", role: "img", "aria-label": "Attention", children: "!" }), (0, jsx_runtime_1.jsx)("p", { children: loading
                                        ? 'Your receipt is being prepared for download...'
                                        : 'Your receipt can now be downloaded and signed in person.' })] }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-primary button-warn print-receipt-button", invalid: loading, submitting: loading, onClick: () => {
                                handlePrintSigned(transactionId);
                            }, children: "PRINT RECEIPT" }), (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-warn-outline", type: "button", onClick: onRequestClose, children: "CLOSE" })] })) : ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("h3", { children: "Signature Required for Receipt" }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)("div", { className: "attention-paragraph", children: [(0, jsx_runtime_1.jsx)("span", { className: "icon-text-attention", role: "img", "aria-label": "Attention", children: "!" }), (0, jsx_runtime_1.jsx)("p", { children: "Your digital receipt has been generated but is currently pending completion due to a required digital signature." })] }), (0, jsx_runtime_1.jsxs)("div", { className: "attention-paragraph", children: [(0, jsx_runtime_1.jsx)("span", { className: "icon-text-attention", role: "img", "aria-label": "Attention", children: "!" }), (0, jsx_runtime_1.jsx)("p", { children: "If you prefer, instead of waiting for the patient to sign the digital receipt, you may click the 'print receipt' below. By choosing to print the receipt right now, you must ask the patient to sign this physical copy of the receipt (rather than digitally) and retain the physical receipt for your records." })] }), (0, jsx_runtime_1.jsx)("div", { className: "sms-extra-checkbox", children: (0, jsx_runtime_1.jsxs)("label", { children: [(0, jsx_runtime_1.jsx)("input", { type: "checkbox", name: "confirm_sms_agreement", onChange: (e) => setConfirm(e.target.checked), value: confirm ? 'checked' : '' }), (0, jsx_runtime_1.jsx)("p", { children: "By checking this box, you confirm the acknowledgement of the two above statements." })] }) }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-primary button-warn print-receipt-button", invalid: loading || confirm !== true, submitting: loadingSignReceipt, onClick: () => {
                                        handleCreateAndSignReceipt(transactionId);
                                    }, children: "PRINT RECEIPT" }), (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-warn-outline", type: "button", onClick: onRequestClose, children: "CANCEL" })] })] })) })] }));
};
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {
    flashErrorMessage: redux_flash_1.flashErrorMessage,
};
const mapApiAuthToProps = {
    getSalesReceipt: apiActions.getSalesReceipt,
    getSalesReceiptSigned: apiActions.getSalesReceiptSigned,
    signReceiptV2: apiActions.signReceiptV2,
    createReceipt: apiActions.createReceipt,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(PrintReceiptButton);
