"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
function AccessOfferEstimateCalculatorForm({ onSubmit, isFetching, paymentFrequency, financedAmount, }) {
    const [transactionAmount, setTransactionAmount] = (0, react_1.useState)('');
    const handleSubmit = () => {
        onSubmit({
            financedAmount: transactionAmount,
        });
    };
    const MIN_TRANSACTION_AMOUNT = 300;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "access-offer-estimate-calculator", children: [(0, jsx_runtime_1.jsx)(components_1.InputNumber, { label: "Transaction Amount", datatestid: "transaction-amount", type: "text", preicon: "$", isAllowed: (values) => {
                    const { value } = values;
                    return value.length < 18;
                }, value: transactionAmount, onChange: (e) => setTransactionAmount(e.target.value) }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", onClick: handleSubmit, className: "button-warn-outline", pristine: isFetching ||
                    !paymentFrequency ||
                    !transactionAmount ||
                    Number(transactionAmount) < MIN_TRANSACTION_AMOUNT ||
                    Number(transactionAmount) > financedAmount, submitting: isFetching, "data-testid": "access-offer-calculate-button", disabledToolTip: `Transaction amount must be between $300 and the pre-qualified amount of $${financedAmount}.`, children: "CALCULATE" })] }));
}
exports.default = AccessOfferEstimateCalculatorForm;
