"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const components_1 = require("components");
function LinkButton({ children, className, invalid, to = '#', ...rest }, ref) {
    const buttonClassName = className ?? 'button-secondary';
    const buttonInvalid = invalid ?? false;
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: buttonInvalid ? '#' : to, ...rest, children: (0, jsx_runtime_1.jsx)(components_1.Button, { ref: ref, type: "button", className: buttonClassName, invalid: buttonInvalid, children: children }) }));
}
exports.default = (0, react_1.forwardRef)(LinkButton);
