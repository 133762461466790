"use strict";
// Adapted from
// https://github.com/jorgegilramos/screen-dpi-detector
Object.defineProperty(exports, "__esModule", { value: true });
exports.dpiDetector = void 0;
const dpiDetector = () => {
    const version = '1.0.0';
    const maxRatio = 8.0;
    const Detector = {
        _version: '',
        screens: {},
        prefix: '',
        devicePixelRatio: 0,
        dppx: 0,
        dpi: 0,
    };
    Detector._version = version;
    // support for device-pixel-ratio: prefix for vendor, "" for native or undefined
    Detector.prefix = (function () {
        if (window.matchMedia) {
            const prefixes = '-webkit-min- min--moz- -o-min- -ms-min- -khtml-min- '.split(' ');
            const mediaQuery = 'device-pixel-ratio:1.0';
            for (let i = 0; i < prefixes.length; i++) {
                if (window.matchMedia('(' + prefixes[i] + mediaQuery + ')').matches) {
                    return prefixes[i];
                }
            }
        }
        return '';
    })();
    // device-pixel-ratio: Number of device pixels per CSS Pixel
    Detector.devicePixelRatio = (function () {
        if (Detector.prefix) {
            let maxdpr = 1.0;
            let i = 1.0;
            for (; i <= maxRatio; i = parseFloat((i + 0.1).toFixed(1))) {
                if (window.matchMedia('(' + Detector.prefix + 'device-pixel-ratio:' + i.toFixed(1) + ')').matches === false) {
                    break;
                }
                else {
                    maxdpr = i;
                }
            }
            return maxdpr;
        }
        return 0;
    })();
    // dppx: Number of dots per px unit. 1dppx = 96dpi
    Detector.dppx = (function () {
        if (window.matchMedia) {
            let maxdppx = 1.0;
            let i = 1.0;
            for (; i <= maxRatio; i = parseFloat((i + 0.1).toFixed(1))) {
                if (window.matchMedia('(min-resolution:' + i.toFixed(1) + 'dppx)')
                    .matches === false) {
                    break;
                }
                else {
                    maxdppx = i;
                }
            }
            return maxdppx;
        }
        return 0;
    })();
    // dpi: Dots per inch
    Detector.dpi = Detector.dppx && Detector.dppx * 96;
    const test = {
        ldpi: 120,
        mdpi: 160,
        hdpi: 240,
        xhdpi: 320,
        xxhdpi: 480,
        xxxhdpi: 640,
    };
    for (const key in test) {
        if (test.hasOwnProperty(key)) {
            Detector.screens[key] =
                Detector.dpi >= test[key];
        }
    }
    return Detector;
};
exports.dpiDetector = dpiDetector;
