"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function forceRedirect({ to }) {
    if ((0, lodash_1.isEmpty)(to))
        throw new Error('forced redirect with empty redirect path');
    window.location.assign(to);
    return null;
}
exports.default = forceRedirect;
