"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_1 = require("react");
const components_1 = require("components");
const utils_1 = require("utils");
const ReconciliationReportActionCell = ({ data, detailedLocations, toggleLocation, selectAllLocation, allSelected, }) => {
    const { achEffectiveDate, locationNumber, productType, postDate } = data;
    const [checked, setChecked] = (0, react_1.useState)(detailedLocations.some((0, utils_1.matchingDetailCriteria)(achEffectiveDate, locationNumber, productType, postDate)));
    const handleChange = () => {
        if ((0, lodash_1.isNil)(locationNumber)) {
            selectAllLocation(!allSelected);
            return null;
        }
        setChecked(!checked);
        toggleLocation(achEffectiveDate, locationNumber, productType, postDate);
    };
    if ((0, lodash_1.isNil)(locationNumber)) {
        return ((0, jsx_runtime_1.jsx)("td", { className: "actions select-all-td", children: (0, jsx_runtime_1.jsx)(components_1.ButtonLink, { onClick: handleChange, className: "select-all button-link", children: allSelected ? 'Deselect All' : 'Select All' }) }));
    }
    return ((0, jsx_runtime_1.jsx)("td", { className: "actions", children: (0, jsx_runtime_1.jsx)("input", { type: "checkbox", checked: checked, onChange: handleChange }) }));
};
exports.default = (0, react_1.memo)(ReconciliationReportActionCell);
