"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const lp_redux_api_1 = require("lp-redux-api");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_flash_1 = require("redux-flash");
const apiActions = __importStar(require("api-actions"));
const components_1 = require("components");
const config_1 = require("config");
const utils_1 = require("utils");
const forms_1 = require("../forms");
const reducer_1 = require("../reducer");
function StartApplication({ currentLocation, loading, createPushApplication, flashSuccessMessage, sendExternalReferencesCommunication, currentPermissions, }) {
    const history = (0, react_router_dom_1.useHistory)();
    const dashboardAppEnabledForState = !config_1.STATES_WITH_DASHBOARD_APPS_DISABLED.includes(currentLocation.address?.state);
    const handleSubmit = () => {
        history.push(config_1.ACCOUNT_FULL_APPLICATION_URL);
    };
    const handlePushApplicationSubmit = (formValues) => {
        const newCreditApplication = {
            location_id: currentLocation.locationId,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            dateOfBirth: formValues.dateOfBirth,
            primaryPhone: formValues.primaryPhone,
        };
        return createPushApplication(newCreditApplication).then((creditApplictionResponse) => {
            const { creditApplication: { applicationId }, } = creditApplictionResponse;
            const pushAppUrl = (0, config_1.makePath)(config_1.CONSUMER_APP_URL, config_1.PATH.CREDIT_PORTAL, config_1.PATH.APPLICANTS, config_1.PATH.PUSH_APPLICATION);
            const requestData = {
                applicationCallbackUrl: pushAppUrl,
                sms: newCreditApplication.primaryPhone,
                template: config_1.PUSH_APPLICATION_SMS_TEMPLATE_NAME,
            };
            return sendExternalReferencesCommunication(applicationId, requestData)
                .then((tokenResponse) => {
                return {
                    token: tokenResponse,
                    applicant: creditApplictionResponse.applicant,
                };
            })
                .catch((e) => {
                if (e?.response?.errorCode === 'patient_sms_opt_out') {
                    throw new Error(e.response.message);
                }
                throw new Error(`We were unable to text message
            ${creditApplictionResponse.applicant.firstName} ${creditApplictionResponse.applicant.lastName} at ${creditApplictionResponse.applicant.primaryPhone}.
            Please ensure that the Patient has a working mobile phone number.`);
            });
        });
    };
    const handlePushApplicationSubmitSuccess = (response) => {
        flashSuccessMessage(`We have successfully sent an SMS with an application link to ${response.applicant.firstName} ${response.applicant.lastName}`);
        history.push(config_1.PATH.MERCHANT_PORTAL);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: "application-container", children: [!(0, lodash_1.isNil)(currentLocation) &&
                currentLocation.pushApplicationEnabled === true &&
                (0, utils_1.operationPermitted)(currentPermissions, config_1.PERMISSION.SUBMIT_PUSH_APPLICATIONS) && ((0, jsx_runtime_1.jsxs)("div", { className: "account-lookup start-application", children: [(0, jsx_runtime_1.jsx)("h1", { children: "Send One-Time Application" }), (0, jsx_runtime_1.jsxs)("div", { className: "start-application__content push-app", children: [(0, jsx_runtime_1.jsx)("div", { className: "start-application__disclosure m-t-50", children: (0, jsx_runtime_1.jsxs)("div", { className: "start-application__disclosure--header", children: ["See if they prequalify with no impact to their credit score. Kick-start the financial conversation with your patients by sending an easy-to-complete application directly to their mobile device. When they check if they prequalify it\u2019s a soft inquiry, with no impact to their credit score. Simply complete the information below and click ", (0, jsx_runtime_1.jsx)("span", { children: "Send Application" }), ". Remind them to have their mobile device ready to complete the application.", (0, jsx_runtime_1.jsx)(forms_1.PushApplicationForm, { onSubmit: handlePushApplicationSubmit, onSubmitSuccess: handlePushApplicationSubmitSuccess, onSubmitFail: utils_1.flashSubmitFailure })] }) }), !(0, lodash_1.isNil)(currentLocation) &&
                                dashboardAppEnabledForState &&
                                (0, utils_1.operationPermitted)(currentPermissions, config_1.PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS) && (0, jsx_runtime_1.jsx)("div", { className: "separator", children: "OR" })] })] })), !(0, lodash_1.isNil)(currentLocation) &&
                dashboardAppEnabledForState &&
                (0, utils_1.operationPermitted)(currentPermissions, config_1.PERMISSION.SUBMIT_DASHBOARD_APPLICATIONS) && ((0, jsx_runtime_1.jsxs)("div", { className: "account-lookup start-application", children: [(0, jsx_runtime_1.jsx)("h1", { children: "Start a Full Application" }), (0, jsx_runtime_1.jsxs)("div", { className: "start-application__content", children: [(0, jsx_runtime_1.jsxs)("div", { className: "start-application__disclosure", children: [(0, jsx_runtime_1.jsxs)("div", { className: "start-application__disclosure--header", children: ["Start a new application with a full credit check. Provide patients with an optimal experience by completing the application on their behalf. A full application is a hard inquiry to your patient\u2019s credit. Simply click", ' ', (0, jsx_runtime_1.jsx)("span", { children: "Start Application" }), " and enter the information they provide. Remember to share the disclosures/agreement with the patient using either the pre-printed booklet provided by Alphaeon Credit or printing via the link below."] }), (0, jsx_runtime_1.jsx)("div", { className: "start-application__disclosure--print", children: (0, jsx_runtime_1.jsx)(components_1.LinkButton, { to: `${config_1.PRINT_URL}/CCA`, target: "_blank", className: "button-warn-outline sale", children: "PRINT DISCLOSURES/AGREEMENT" }) })] }), (0, jsx_runtime_1.jsx)("div", { className: "start-application__disclosure m-t-50", children: (0, jsx_runtime_1.jsxs)("div", { className: "start-application__disclosure--header", children: ["Please confirm the following prior to submitting the application:", (0, jsx_runtime_1.jsx)(forms_1.StartApplicationForm, { onSubmit: handleSubmit, submitting: loading })] }) })] })] }))] }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
        loading: lp_redux_api_1.selectors.isLoading(state, 'CREATE_PUSH_APPLICATION'),
        currentPermissions: reducer_1.selectors.currentPermissions(state),
    };
}
const mapDispatchToProps = {
    flashSuccessMessage: redux_flash_1.flashSuccessMessage,
};
const mapApiAuthToProps = {
    createPushApplication: apiActions.createPushApplication,
    sendExternalReferencesCommunication: apiActions.sendExternalReferencesCommunication,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps), (0, utils_1.withApiAuth)(mapApiAuthToProps))(StartApplication);
