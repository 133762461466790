"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const parsePhoneNumber = (value) => {
    if (!value) {
        return '';
    }
    const onlyNumbers = value.replace(/[^\d]/g, '');
    if (onlyNumbers.length < 1) {
        return '';
    }
    if (onlyNumbers.length < 3) {
        return '(' + onlyNumbers;
    }
    if (onlyNumbers.length === 3) {
        return '(' + onlyNumbers;
    }
    if (onlyNumbers.length < 7) {
        return '(' + onlyNumbers.slice(0, 3) + ') ' + onlyNumbers.slice(3);
    }
    if (onlyNumbers.length > 10) {
        return value.slice(0, value.length - 1);
    }
    return ('(' +
        onlyNumbers.slice(0, 3) +
        ') ' +
        onlyNumbers.slice(3, 6) +
        '-' +
        onlyNumbers.slice(6));
};
exports.default = parsePhoneNumber;
