"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@coreui/react");
const react_router_dom_1 = require("react-router-dom");
const portal_api_1 = require("portal-api");
function PlanManagement({}) {
    const { id } = (0, react_router_dom_1.useParams)();
    const { data: planSummaries } = (0, portal_api_1.useGetInternalPortalV1LocationsLocationIdPlanSummariesQuery)({
        locationId: Number(id),
    });
    if (planSummaries == undefined || !planSummaries.plans?.length) {
        return '';
    }
    const pricingTierName = planSummaries.pricingTierName;
    const locationName = planSummaries.location?.name;
    const plans = planSummaries.plans;
    return ((0, jsx_runtime_1.jsxs)("div", { className: "main-content-container", children: [(0, jsx_runtime_1.jsxs)("h1", { style: { paddingBottom: '50px' }, children: [pricingTierName, " pricing tier for ", locationName] }), (0, jsx_runtime_1.jsx)("div", { className: "table-container", children: (0, jsx_runtime_1.jsxs)(react_1.CTable, { bordered: true, children: [(0, jsx_runtime_1.jsx)(react_1.CTableHead, { children: (0, jsx_runtime_1.jsxs)(react_1.CTableRow, { children: [(0, jsx_runtime_1.jsx)(react_1.CTableHeaderCell, { children: "Plan Name" }), (0, jsx_runtime_1.jsx)(react_1.CTableHeaderCell, { children: "Setting Source" }), (0, jsx_runtime_1.jsx)(react_1.CTableHeaderCell, { children: "Purchase Range" }), (0, jsx_runtime_1.jsx)(react_1.CTableHeaderCell, { children: "Enabled" })] }) }), (0, jsx_runtime_1.jsx)(react_1.CTableBody, { children: plans.map((plan, index) => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_1.CTableRow, { style: { height: '5em' }, children: (0, jsx_runtime_1.jsx)(react_1.CTableDataCell, { colSpan: 5, style: {
                                                fontSize: '1em',
                                                fontWeight: 'bold',
                                                width: '200%',
                                            }, children: plan.plan?.name }) }, index), plan.planPreferences &&
                                        plan.planPreferences
                                            .sort((a) => (a.source === 'Merchant' ? -1 : 1))
                                            .map((preference, prefIndex) => ((0, jsx_runtime_1.jsxs)(react_1.CTableRow, { style: { height: '5em' }, children: [(0, jsx_runtime_1.jsx)(react_1.CTableDataCell, { style: preference.source === 'Merchant' &&
                                                        plan.planPreferences &&
                                                        plan.planPreferences.length > 1
                                                        ? { color: 'rgba(255, 0, 0, 1)' }
                                                        : {} }), (0, jsx_runtime_1.jsx)(react_1.CTableDataCell, { style: preference.source === 'Merchant' &&
                                                        plan.planPreferences &&
                                                        plan.planPreferences.length > 1
                                                        ? { color: 'rgba(255, 0, 0, 1)' }
                                                        : {}, children: preference.source }), (0, jsx_runtime_1.jsx)(react_1.CTableDataCell, { style: preference.source === 'Merchant' &&
                                                        plan.planPreferences &&
                                                        plan.planPreferences.length > 1
                                                        ? { color: 'rgba(255, 0, 0, 1)' }
                                                        : {}, children: `$${new Intl.NumberFormat('en-US').format(preference.minPurchaseAmount
                                                        ? parseFloat(preference.minPurchaseAmount)
                                                        : 0)} - $${new Intl.NumberFormat('en-US').format(preference.maxPurchaseAmount
                                                        ? parseFloat(preference.maxPurchaseAmount)
                                                        : 0)}` }), (0, jsx_runtime_1.jsx)(react_1.CTableDataCell, { style: preference.source === 'Merchant' &&
                                                        plan.planPreferences &&
                                                        plan.planPreferences.length > 1
                                                        ? { color: 'rgba(255, 0, 0, 1)' }
                                                        : {}, children: preference.dashboardEnabled ? '✔️' : '❌️' })] }, `${index}-${prefIndex}`)))] }))) })] }) })] }));
}
exports.default = PlanManagement;
