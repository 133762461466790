"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
const UploadNav = ({ displayInfo, handleCallback, pageTitle, noDocsNeeded, displayBasicBtns = false, returningToDashboard, }) => {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "doc-upload-container", children: [displayInfo && !!displayInfo.length && noDocsNeeded === false && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "btn button-warn-outline return-to-dash", onClick: handleCallback, submitting: returningToDashboard, children: pageTitle
                        ? 'Return to Location Management'
                        : 'RETURN TO DASHBOARD' }) })), displayBasicBtns && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "btn button-warn-outline return-to-dash", onClick: handleCallback, submitting: returningToDashboard, children: pageTitle
                        ? 'Return to Location Management'
                        : 'RETURN TO DASHBOARD' }) }))] }));
};
exports.default = UploadNav;
