"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function approveTerms(...args) {
    const allTerms = (0, lodash_1.flatten)(args);
    return allTerms.map(({ termContentId }) => ({
        termContentId,
        acceptedOn: new Date().toISOString(),
    }));
}
exports.default = approveTerms;
