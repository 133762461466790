"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLenderReferenceId = exports.setLocationNotificationsDismissed = exports.setCurrentLocationIndex = exports.clearAllLocations = exports.clearMerchantApplicationEnvironment = exports.setApplicationInfo = exports.clearMerchantPortal = void 0;
const redux_actions_1 = require("redux-actions");
exports.clearMerchantPortal = (0, redux_actions_1.createAction)('CLEAR_MERCHANT_PORTAL');
exports.setApplicationInfo = (0, redux_actions_1.createAction)('MERCHANT_APPLICATION/SET_APPLICATION_INFO');
exports.clearMerchantApplicationEnvironment = (0, redux_actions_1.createAction)('CLEAR_MERCHANT_APPLICATION_ENVIRONMENT');
exports.clearAllLocations = (0, redux_actions_1.createAction)('CLEAR_ALL_LOCATIONS');
exports.setCurrentLocationIndex = (0, redux_actions_1.createAction)('SET_CURRENT_LOCATION_INDEX');
exports.setLocationNotificationsDismissed = (0, redux_actions_1.createAction)('SET_LOCATION_NOTIFICATIONS_DISMISSED');
exports.setLenderReferenceId = (0, redux_actions_1.createAction)('MERCHANT_APPLICATION/SET_LENDER_REFERENCE_ID');
