"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getMonthFromDate = (date) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const currentMonth = date.getMonth();
    return monthNames[currentMonth];
};
exports.default = getMonthFromDate;
