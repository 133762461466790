"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
function LegalShow() {
    return (0, jsx_runtime_1.jsx)("div", { children: " This is the Legal show view! " });
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(LegalShow);
