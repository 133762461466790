"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const formatDateFromInputString = (value) => {
    if (!value) {
        return '';
    }
    const onlyNumbers = value.replace(/[^\d]/g, '');
    if (onlyNumbers.length < 3) {
        return onlyNumbers;
    }
    if (onlyNumbers.length > 8) {
        return value.slice(0, value.length - 1);
    }
    if (onlyNumbers.length < 5) {
        return onlyNumbers.slice(0, 2) + '/' + onlyNumbers.slice(2);
    }
    return (onlyNumbers.slice(0, 2) +
        '/' +
        onlyNumbers.slice(2, 4) +
        '/' +
        onlyNumbers.slice(4));
};
exports.default = formatDateFromInputString;
