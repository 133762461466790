"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const config_1 = require("config");
function ApplicationNotFound() {
    return ((0, jsx_runtime_1.jsxs)("div", { className: "action-result-container", children: [(0, jsx_runtime_1.jsxs)("div", { className: "main-content", children: [(0, jsx_runtime_1.jsx)("header", { children: (0, jsx_runtime_1.jsx)("h1", { children: "No Application Found" }) }), (0, jsx_runtime_1.jsx)("p", { children: "Thank you for your interest in the Alphaeon Credit Card." }), (0, jsx_runtime_1.jsx)("p", { children: "We were unable to find any open applications." })] }), (0, jsx_runtime_1.jsx)("div", { className: "next-step", children: (0, jsx_runtime_1.jsxs)("p", { children: ["You can start a new application by clicking", ' ', (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: config_1.CREDIT_PORTAL_URL, children: "here" }), "."] }) })] }));
}
exports.default = (0, react_1.memo)(ApplicationNotFound);
