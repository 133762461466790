"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
function AuthorizedBuyers({ authBuyers }) {
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: authBuyers &&
            authBuyers.map((buyer) => {
                const { firstName, lastName, relationship, authorizedBuyerAddedDate, } = buyer;
                return ((0, jsx_runtime_1.jsxs)("span", { children: [firstName, " ", lastName, ' ', (0, jsx_runtime_1.jsx)("span", { className: "capitalize", children: relationship && relationship !== 'N/A' && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["- ", relationship, " "] })) }), authorizedBuyerAddedDate && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: ["- Added -", ' ', authorizedBuyerAddedDate == '9999-12-12'
                                    ? 'N/A'
                                    : (0, date_fns_1.format)(new Date(authorizedBuyerAddedDate), 'MM/dd/yyyy')] })), (0, jsx_runtime_1.jsx)("br", {})] }, authorizedBuyerAddedDate + firstName));
            }) }));
}
exports.default = AuthorizedBuyers;
