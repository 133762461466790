"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSearchCriteria = exports.setSearchValue = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    searchValue: '',
    searchCriteria: 'Phone Number',
};
const slice = (0, toolkit_1.createSlice)({
    name: 'globalSearch',
    initialState,
    reducers: {
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        setSearchCriteria: (state, action) => {
            state.searchCriteria = action.payload;
        },
    },
});
_a = slice.actions, exports.setSearchValue = _a.setSearchValue, exports.setSearchCriteria = _a.setSearchCriteria;
exports.default = slice.reducer;
