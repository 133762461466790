"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const config_1 = require("config");
const utils_1 = require("utils");
const handleClientError = (status) => {
    const pathname = window.location.pathname;
    window.location.assign((0, utils_1.makeQueryPath)(config_1.PATH.CLIENT_ERROR, {
        [config_1.QUERY_KEY.APPLICATION]: getApplicationFromPath(pathname),
        [config_1.QUERY_KEY.STATUS]: status,
    }));
};
exports.default = handleClientError;
const getApplicationFromPath = (pathname) => {
    // The application will typically be the first component of the pathname...
    const applicationName = (0, lodash_1.first)(pathname.slice(1).split('/'));
    return applicationName ? applicationName : '';
};
