"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
function NewDealNonSignerModal({ isOpen, setIsOpen, currentLocation, setShowAuthorizedSignerModal, setShouldScrollToBottom, }) {
    const { signers = [] } = currentLocation;
    const handleClose = () => {
        sessionStorage.setItem('displayNewDeal', 'false');
        setIsOpen(false);
        setShouldScrollToBottom(true);
    };
    const handleAuthorizedSigner = () => {
        setShowAuthorizedSignerModal(true);
        setIsOpen(false);
    };
    return ((0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, children: (0, jsx_runtime_1.jsxs)("div", { className: "new-deal-non-signer-modal-container", children: [(0, jsx_runtime_1.jsx)("h1", { children: "Action Required" }), (0, jsx_runtime_1.jsx)("p", { children: "The patient's transaction has been approved by the lender and posted to their Alphaeon Credit card account. In order for Alphaeon Credit to fund your practice for this sale, we need the Authorized Signer to review your practice's information and agree to our updated Terms and Conditions." }), !signers.length ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: "We do not have information on who your authorized signer is. As soon as possible, please add an Authorized Signer." }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn", onClick: handleAuthorizedSigner, children: "ADD AUTHORIZED SIGNER" })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("p", { children: "Please have an Authorized Signer log in and complete this process." }), (0, jsx_runtime_1.jsx)("div", { className: "signers-list", children: (0, jsx_runtime_1.jsx)("span", { children: signers.map((signer, index) => ((0, jsx_runtime_1.jsx)("span", { children: (0, jsx_runtime_1.jsxs)("a", { href: `mailto:${signer.email}`, children: [signer.email, index !== signers.length - 1 && ',', ' '] }) }, index))) }) })] })), (0, jsx_runtime_1.jsx)("p", { children: "You can continue to use your Dashboard, including processing sales, however the practice will not receive any proceeds until the above action is completed." }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn-outline", onClick: handleClose, children: "CLOSE TO PRINT RECEIPT" })] }) }));
}
exports.default = NewDealNonSignerModal;
