"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function CodeInput({ setInputValue }) {
    const [currentIndex, setCurrentIndex] = (0, react_1.useState)(1);
    const inputRef1 = (0, react_1.useRef)(null);
    const inputRef2 = (0, react_1.useRef)(null);
    const inputRef3 = (0, react_1.useRef)(null);
    const inputRef4 = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        inputRef1?.current?.focus();
    }, []);
    const updateInputValue = () => {
        setInputValue(`${inputRef1.current?.value}${inputRef2.current?.value}${inputRef3.current?.value}${inputRef4.current?.value}`);
    };
    (0, react_1.useEffect)(() => {
        if (currentIndex === 1) {
            inputRef1?.current?.focus();
        }
        else if (currentIndex === 2) {
            inputRef2?.current?.focus();
        }
        else if (currentIndex === 3) {
            inputRef3?.current?.focus();
        }
        else if (currentIndex === 4) {
            inputRef4?.current?.focus();
        }
    }, [currentIndex]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "code-input-container", children: [(0, jsx_runtime_1.jsx)(DigitInput, { index: 1, setCurrentIndex: setCurrentIndex, inputRef: inputRef1, updateInputValue: updateInputValue }), (0, jsx_runtime_1.jsx)(DigitInput, { index: 2, setCurrentIndex: setCurrentIndex, inputRef: inputRef2, updateInputValue: updateInputValue }), (0, jsx_runtime_1.jsx)(DigitInput, { index: 3, setCurrentIndex: setCurrentIndex, inputRef: inputRef3, updateInputValue: updateInputValue }), (0, jsx_runtime_1.jsx)(DigitInput, { index: 4, setCurrentIndex: setCurrentIndex, inputRef: inputRef4, updateInputValue: updateInputValue })] }));
}
function DigitInput({ index, setCurrentIndex, inputRef, updateInputValue, }) {
    const [value, setValue] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        updateInputValue();
    }, [value]);
    const isValidNumber = (number) => {
        return /^[0-9]*$/.test(number);
    };
    const handleFocus = () => {
        setCurrentIndex(index);
    };
    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            setValue('');
            setCurrentIndex(index - 1);
        }
        else if (!isValidNumber(e.key) || e.key === value) {
            return;
        }
        else if (e.target.selectionStart === 0) {
            setValue(e.key);
            setCurrentIndex(index + 1);
        }
        else if (value.length === 1) {
            setCurrentIndex(index + 1);
        }
    };
    return ((0, jsx_runtime_1.jsx)("input", { className: "digit-input", type: "tel", maxLength: 1, onFocus: handleFocus, value: value, onKeyDown: handleKeyDown, ref: inputRef }));
}
exports.default = CodeInput;
