"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function operationPermitted(currentPermissions, requiredPermission, selectionCriteria = 'ALL') {
    if ((0, lodash_1.isNil)(currentPermissions))
        return false;
    const checkPermissions = (permission) => {
        const isPermitted = currentPermissions[permission];
        return (0, lodash_1.isNil)(isPermitted) ? false : isPermitted;
    };
    if ((0, lodash_1.isArray)(requiredPermission)) {
        if (selectionCriteria === 'OR') {
            return requiredPermission.some((permission) => checkPermissions(permission));
        }
        return requiredPermission.every((permission) => checkPermissions(permission));
    }
    return checkPermissions(requiredPermission);
}
exports.default = operationPermitted;
