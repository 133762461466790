"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function validateRoutingNumber(value) {
    if (!value) {
        return 'Required';
    }
    if (value.length !== 9) {
        return 'Enter a valid 9-digit routing number.';
    }
}
exports.default = validateRoutingNumber;
