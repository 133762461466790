"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function formatEIN(value) {
    if (!value) {
        return '';
    }
    if (value.length >= 20) {
        return value.slice(0, value.length - 1);
    }
    if (/^[0-9\-]+$/.test(value)) {
        return value;
    }
    return value.slice(0, value.length - 1);
}
exports.default = formatEIN;
