"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.reducer = void 0;
const redux_1 = require("redux");
const credit_portal_1 = require("./credit-portal");
const globalReducer_1 = require("./globalReducer");
const internal_portal_1 = require("./internal-portal");
const legal_1 = require("./legal");
const merchant_portal_1 = require("./merchant-portal");
const styleguide_1 = require("./styleguide");
const reducerKey = 'root';
exports.reducerKey = reducerKey;
const reducer = (0, redux_1.combineReducers)({
    [globalReducer_1.reducerKey]: globalReducer_1.reducer,
    [credit_portal_1.reducerKey]: credit_portal_1.reducer,
    [legal_1.reducerKey]: legal_1.reducer,
    [merchant_portal_1.reducerKey]: merchant_portal_1.reducer,
    [styleguide_1.reducerKey]: styleguide_1.reducer,
    [internal_portal_1.reducerKey]: internal_portal_1.reducer,
});
exports.reducer = reducer;
