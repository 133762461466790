"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
function InputRadio({ input, meta, ...rest }) {
    const cleanRest = {
        ...rest,
        label: undefined, // Removed or console error
        radiotext: undefined,
        errhide: undefined,
    };
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsxs)("div", { className: "flex align-center", children: [(0, jsx_runtime_1.jsx)("input", { type: "radio", ...input, ...cleanRest }), rest.radiotext ? (0, jsx_runtime_1.jsxs)("span", { children: [" ", rest.radiotext] }) : null] }), (meta.error || meta.submitError) && meta.touched && !rest.errhide && ((0, jsx_runtime_1.jsx)("span", { className: "error-message", children: meta.error || meta.submitError }))] }));
}
exports.default = InputRadio;
