"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_router_dom_1 = require("react-router-dom");
const config_1 = require("config");
const GoBack = ({ distance }) => {
    // Hooks
    const history = (0, react_router_dom_1.useHistory)();
    const hasHistory = () => {
        const historyLength = window.history.length;
        return historyLength > 2;
    };
    const scrollTop = () => {
        window.scrollTo(0, 0);
    };
    const handleViewSummary = () => {
        scrollTop();
        history.go(distance);
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: "account-lookup m-t-30", children: (0, jsx_runtime_1.jsxs)("div", { className: "text-center", children: [(0, jsx_runtime_1.jsx)("h2", { children: "Sorry!" }), (0, jsx_runtime_1.jsx)("h6", { children: "You cannot refresh the page when processing a sale." }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), hasHistory() ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-primary button-warn-outline button-lg", type: "button", onClick: () => handleViewSummary(), children: "View Account Summary" }), (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-primary button-warn-outline button-lg", type: "button", onClick: () => history.replace(config_1.ACCOUNT_LOOKUP_URL), children: "View Account Lookup" })] })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-primary button-warn", type: "button", onClick: () => history.replace(config_1.ACCOUNT_LOOKUP_URL), children: "Start Over" }) }))] }) }));
};
exports.default = GoBack;
