"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const config_1 = require("config");
const useIovation = () => {
    (0, react_1.useEffect)(() => {
        const script = document.createElement('script');
        script.src = config_1.URL.IOVATION_JS;
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    const getBlackboxData = () => {
        let blackboxData;
        try {
            // @ts-expect-error
            blackboxData = ioGetBlackbox();
        }
        catch (e) {
            alert('Unable to get blackbox. ' + e);
        }
        return blackboxData;
    };
    return [getBlackboxData];
};
exports.default = useIovation;
