"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const utils_1 = require("utils");
const reducer_1 = require("../reducer");
function CalculatorPrintPlansForm({ handleSubmit, loading = false, currentLocation, plansForPrinting, purchaseAmount, initialize, }) {
    (0, react_1.useEffect)(() => {
        const planIds = plansForPrinting?.map((plan) => plan.id);
        initialize({
            plan_ids: planIds,
            purchase_amount: purchaseAmount,
            location_id: currentLocation?.locationId,
            practice_name: currentLocation?.name,
            down_payment: 0,
            insurance: 0,
            cash_discount_percentage: 0,
        });
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "calculator-print-to-pdf-form", children: [(0, jsx_runtime_1.jsx)("div", { className: "title", children: (0, jsx_runtime_1.jsx)("h4", { children: "Calculator Inputs" }) }), (0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsxs)("div", { className: "row", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "first_name", component: lp_components_1.Input, placeholder: "First Name", label: "First Name", required: true, validate: utils_1.validateName }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "last_name", component: lp_components_1.Input, placeholder: "Last Name", label: "Last Name", required: true, validate: utils_1.validateName })] }), (0, jsx_runtime_1.jsx)("div", { className: "row", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { submitting: loading, className: "button-warn-outline", children: "SUBMIT" }) })] })] }));
}
function mapStateToProps(state) {
    return {
        currentLocation: reducer_1.selectors.currentLocation(state),
    };
}
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps)((0, redux_form_1.reduxForm)({
    form: 'CalculatorPrintToPdf',
})(CalculatorPrintPlansForm)));
