"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const components_1 = require("components");
function NewDealSignerModal({ isOpen, setIsOpen, setShouldScrollToBottom, }) {
    const handleCloseAndScrollToBottom = () => {
        setIsOpen(false);
        setShouldScrollToBottom(true);
    };
    const handleClose = () => {
        sessionStorage.setItem('displayNewDeal', 'false');
        setIsOpen(false);
    };
    return ((0, jsx_runtime_1.jsx)(components_1.Modal, { isOpen: isOpen, setIsOpen: setIsOpen, children: (0, jsx_runtime_1.jsxs)("div", { className: "new-deal-signer-modal-container", children: [(0, jsx_runtime_1.jsx)("h1", { children: "Action Required" }), (0, jsx_runtime_1.jsx)("p", { children: "The patient's transaction has been approved by the lender and posted to their Alphaeon Credit card account. In order for Alphaeon Credit to fund your practice for this sale, we need you to review your practice's information and agree to our updated Terms and Conditions." }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn", onClick: handleCloseAndScrollToBottom, children: "PRINT RECEIPT AND REVIEW MY PRACTICE'S INFORMATION" }), (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: "button-warn-outline", onClick: handleClose, children: "CLOSE" })] }) }));
}
exports.default = NewDealSignerModal;
