"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
let errorCount = 0;
async function fetchApplicationBuildId() {
    if (!(0, config_1.isProduction)() || config_1.REACT_APP_BUILDID_FETCH_INTERVAL === 0) {
        return Promise.resolve(`DEFAULT APPLICATION VERSION`);
    }
    if (navigator.onLine) {
        try {
            const response = await fetch(`${config_1.APPLICATION_ROOT_URL}/buildHash`, {
                cache: 'no-store',
            });
            const buildHash = await response.json();
            const { hash: applicationBuildId } = buildHash;
            errorCount = 0;
            return applicationBuildId;
        }
        catch (error) {
            if (errorCount === 0) {
                ++errorCount;
                // eslint-disable-next-line no-console
                console.warn('[ERROR]: cannot obtain application buildId from server. ERROR: ', error?.message);
                // eslint-disable-next-line no-console
                console.info('[NOTE]: future errors will not be reported until exception is resolved.');
            }
        }
    }
    else {
        return Promise.resolve(null);
    }
}
exports.default = fetchApplicationBuildId;
