"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const fp_1 = require("lodash/fp");
const lp_redux_api_1 = require("lp-redux-api");
const lp_redux_utils_1 = require("lp-redux-utils");
const redux_1 = require("redux");
const redux_actions_1 = require("redux-actions");
const apiActions = __importStar(require("api-actions"));
const actions = __importStar(require("./actions"));
const reducerKey = 'reports';
exports.reducerKey = reducerKey;
const slice = 'root.merchantPortal.reports';
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({
    [actions.clearAllReports.toString()]: (state) => {
        return (0, redux_1.compose)((0, fp_1.unset)('reconciliationReport'))(state);
    },
    [apiActions.getReconciliationReport]: (0, lp_redux_api_1.handleSuccess)((state, { payload: { data: reconciliationReport }, }) => {
        const { bankReconciliations, locationSummaries } = reconciliationReport;
        const sortedBankReconciliations = bankReconciliations.sort(sortByPostDate);
        const sortedLocationSummaries = locationSummaries.sort(sortByPostDate);
        return (0, fp_1.set)('reconciliationReport', {
            ...reconciliationReport,
            bankReconciliations: sortedBankReconciliations,
            locationSummaries: sortedLocationSummaries,
        }, state);
    }),
}, initialState);
exports.reducer = reducer;
const select = (0, lp_redux_utils_1.selectorForSlice)(slice);
const selectors = {
    reconciliationReport: select('reconciliationReport'),
};
exports.selectors = selectors;
function sortByPostDate(a, b) {
    return a.postDate.localeCompare(b.postDate);
}
