"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
function LoanAdjustConfirmationForm({ handleSubmit, handleClose, }) {
    return ((0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: handleSubmit, children: (0, jsx_runtime_1.jsxs)("div", { className: "loan-adjustment-actions", children: [(0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { className: "button-warn m-t-30", children: "Continue" }), (0, jsx_runtime_1.jsx)(lp_components_1.Button, { className: "button-warn-outline m-t-30", onClick: () => handleClose(), children: "Cancel" })] }) }));
}
function mapStateToProps() {
    return {};
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    name: 'loanAdjustmentConfirmationForm',
    constraints: {},
}), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(LoanAdjustConfirmationForm);
