"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calcTransaction = void 0;
const calcTransaction = (transactions) => {
    const transTotal = transactions.reduce((acc, obj) => {
        if (obj.transactionType === 'refund') {
            return acc - obj.amount;
        }
        else {
            return acc + obj.amount;
        }
    }, 0);
    return transTotal;
};
exports.calcTransaction = calcTransaction;
