"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
/**
 * @name useQuery
 * @desc A custom hook that builds on useLocation to parse the query string for you.
 * @ref https://v5.reactrouter.com/web/example/query-parameters
 * @returns URLSearchParams (e.g. query.get("name"))
 */
const useQuery = () => {
    const { search } = (0, react_router_dom_1.useLocation)();
    return (0, react_1.useMemo)(() => new URLSearchParams(search), [search]);
};
exports.default = useQuery;
