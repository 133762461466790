"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addHyphen = void 0;
const addHyphen = (str) => {
    if (str) {
        const result = str.split('');
        result.splice(2, 0, '-');
        return result.join('');
    }
    else {
        return str;
    }
};
exports.addHyphen = addHyphen;
