"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const lp_components_1 = require("lp-components");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const components_1 = require("components");
const config_1 = require("config");
const utils_1 = require("utils");
const actions = __importStar(require("../actions"));
const reducer_1 = require("../reducer");
function ApplicationFound({ personalInfo, prequalifications, setLenderReferenceId, setPractice, setPrequalification, setPendingDecisionDetails, setLenderName, }) {
    const history = (0, react_router_dom_1.useHistory)();
    if ((0, lodash_1.isNil)(prequalifications))
        return (0, jsx_runtime_1.jsx)(react_router_dom_1.Redirect, { to: config_1.CREDIT_PORTAL_URL });
    const { firstName, lastName } = personalInfo;
    const totalPrequalifications = prequalifications.length;
    const totalPrequalificationsPlural = totalPrequalifications > 1 ? 's' : '';
    const navigateToNextView = (prequalification) => {
        const { applicationStatus, lenderNextStepUrl, lenderRequestReferenceId, location, pendingDecisionDetails, } = prequalification;
        setPrequalification(prequalification);
        setPractice(location);
        setLenderReferenceId(lenderRequestReferenceId);
        setPendingDecisionDetails(pendingDecisionDetails);
        if (prequalification.offers &&
            prequalification.offers[0] &&
            prequalification.offers[0].serviceProviderName) {
            const lenderName = prequalification.offers[0].serviceProviderName;
            setLenderName(lenderName);
        }
        else {
            setLenderName('');
        }
        if (applicationStatus === 'prequalified') {
            history.push({
                pathname: config_1.WIZARD_URL,
                state: {
                    step: 'PRE-QUALIFICATION',
                    fromfindMyApp: true,
                },
            });
        }
        else if (applicationStatus === 'pending_approval') {
            // "An assumption is currently made that pending_approval is returned only for access loans. If additional product types associated with
            //  a pending_approval status will be supported, this code this have to be revisited to accommodate them."
            history.push({
                pathname: config_1.ACCESS_LOAN_URL,
                state: {
                    lenderNextStepUrl,
                    modalType: 'no-modal',
                    fromfindMyApp: true,
                    applicationStatus,
                },
            });
        }
        else if (applicationStatus === 'pending') {
            history.push(config_1.PRE_QUALIFICATION_INFORMATION_REQUIRED_URL);
        }
        else {
            throw new Error(`ERROR: unknown API application status: ${applicationStatus}`);
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: "action-result-container", children: (0, jsx_runtime_1.jsxs)("div", { className: "main-content", children: [(0, jsx_runtime_1.jsx)("header", { children: (0, jsx_runtime_1.jsxs)("h1", { children: [totalPrequalifications, " Application", totalPrequalificationsPlural, " Found"] }) }), (0, jsx_runtime_1.jsxs)("p", { children: ["We have found the following existing application", totalPrequalificationsPlural, " for ", firstName, " ", lastName, ":"] }), (0, jsx_runtime_1.jsx)(AllCreditApplications, { onSelect: navigateToNextView, applicant: personalInfo, prequalifications: prequalifications.filter((prequal) => prequal != null) }), (0, jsx_runtime_1.jsx)("p", { children: "Please click \"COMPLETE APPLICATION\" to complete your application." })] }) }));
}
function mapStateToProps(state) {
    return {
        personalInfo: reducer_1.selectors.personalInfo(state),
        prequalifications: reducer_1.selectors.prequalifications(state),
    };
}
const mapDispatchToProps = {
    setLenderReferenceId: actions.setLenderReferenceId,
    setPractice: actions.setPractice,
    setPrequalification: actions.setPrequalification,
    setPendingDecisionDetails: actions.setPendingDecisionDetails,
    setLenderName: actions.setLenderName,
};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(ApplicationFound);
function AllCreditApplications({ applicant, onSelect, prequalifications, }) {
    const ApplyButton = ({ data }) => ((0, jsx_runtime_1.jsx)("td", { className: "action", children: (0, jsx_runtime_1.jsx)(components_1.Button, { className: "button-warn-outline", onClick: () => onSelect(data), type: "button", children: "COMPLETE APPLICATION" }) }));
    return ((0, jsx_runtime_1.jsxs)(lp_components_1.Table, { className: "result-table", data: prequalifications, children: [(0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "name", label: "Applicant", valueGetter: () => (0, utils_1.getFullName)(applicant) }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "location", label: "Location", valueGetter: ({ location }) => location.name }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "applicationOpenedAt", label: "Application Date", format: (applicationOpenedAt) => (0, utils_1.formatISODateStringAsUSMonthDayYear)(applicationOpenedAt) }), (0, jsx_runtime_1.jsx)(lp_components_1.TableColumn, { name: "action", label: "", component: ApplyButton })] }));
}
