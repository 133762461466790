"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const formatISODate = (date) => {
    if ((0, lodash_1.isNil)(date) || String(date).trim() === '')
        return '';
    return (0, date_fns_1.format)(date, 'yyyy-MM-dd');
};
exports.default = formatISODate;
