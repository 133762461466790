"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
const utils_1 = require("utils");
const getAuthorizedReportTypes = (permissions) => {
    const report_types = [];
    if ((0, utils_1.operationPermitted)(permissions, config_1.PERMISSION.VIEW_APPLICATION_TRACKING_REPORT)) {
        report_types.push(config_1.APPLICATION_TRACKING);
    }
    if ((0, utils_1.operationPermitted)(permissions, config_1.PERMISSION.VIEW_BANK_RECONCILIATION_REPORT)) {
        report_types.push(config_1.BANK_RECONCILIATION);
    }
    if ((0, utils_1.operationPermitted)(permissions, config_1.PERMISSION.VIEW_SALE_REPORT)) {
        report_types.push(config_1.SALES);
    }
    if ((0, utils_1.operationPermitted)(permissions, config_1.PERMISSION.VIEW_CREDIT_AVAILABLE_REPORT)) {
        report_types.push(config_1.CREDIT_AVAILABLE);
    }
    return config_1.REPORT_TYPES.filter((reportType) => report_types.includes(reportType.value));
};
exports.default = getAuthorizedReportTypes;
