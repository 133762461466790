"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const react_cookie_1 = require("react-cookie");
const react_redux_1 = require("react-redux");
const config_1 = require("config");
const mockData = __importStar(require("./withApiMock"));
function withGoogleAuth(actionCreatorMap) {
    if (process.env.STORYBOOK_RUNNING === 'true') {
        return (Component) => {
            function WrappedComponent({ ...rest }) {
                const actionCreatorWithAuth = async (actionCreator, ...args) => {
                    const accessToken = '12345';
                    if (actionCreator && actionCreator.hasOwnProperty('type')) {
                        const actionList = {
                            actions: { ...actionCreator(...args, accessToken) },
                            type: actionCreator(...args, accessToken).type,
                        };
                        const actionObj = Object.getOwnPropertySymbols(actionList.actions)
                            .map((s) => actionList.actions[s])
                            .at(0);
                        const tmpData = {
                            ...mockData,
                        };
                        return new Promise((resolve) => {
                            const tmpType = actionObj.type;
                            const returnData = tmpData[tmpType];
                            resolve(returnData);
                        });
                    }
                    else {
                        return new Promise((reject) => {
                            reject(null);
                        });
                    }
                };
                const mappedActionCreators = (0, lodash_1.mapValues)(actionCreatorMap, (actionValue) => (...args) => {
                    return actionCreatorWithAuth(actionValue, ...args);
                });
                return (0, jsx_runtime_1.jsx)(Component, { ...mappedActionCreators, ...rest });
            }
            WrappedComponent.displayName = `withApiAuth(${Component.displayName || Component.name})`;
            return WrappedComponent;
        };
    }
    else {
        return (Component) => {
            function WrappedComponent({ ...rest }) {
                const [cookies] = (0, react_cookie_1.useCookies)(['portalUser']);
                const dispatch = (0, react_redux_1.useDispatch)();
                const actionCreatorWithAuth = async (actionCreator, ...args) => {
                    const accessToken = cookies.portalUser?.credential ?? {};
                    const actionObj = actionCreator(...args, accessToken);
                    const dispatchObj = await dispatch(actionObj);
                    /**
                     * DEBUG REDUX ACTIONS/DISPATCH
                     */
                    if (!(0, config_1.isProduction)() &&
                        String(process.env.REACT_APP_REDUX_API_DEBUG) === 'true') {
                        if (actionCreator && actionCreator.hasOwnProperty('type')) {
                            const actionList = {
                                actions: { ...actionCreator(...args, accessToken) },
                                type: actionCreator(...args, accessToken).type,
                            };
                            const actionObj = Object.getOwnPropertySymbols(actionList.actions)
                                .map((s) => actionList.actions[s])
                                .at(0);
                            /* eslint-disable no-console */
                            console.info('action: ', actionObj.type);
                            /* eslint-disable no-console */
                            console.info('resp: ', dispatchObj);
                        }
                    }
                    /**
                     * END DEBUG
                     */
                    // Return Dispatch (don't forget)
                    return dispatchObj;
                };
                const mappedActionCreators = (0, lodash_1.mapValues)(actionCreatorMap, (actionValue) => (...args) => actionCreatorWithAuth(actionValue, ...args));
                return (0, jsx_runtime_1.jsx)(Component, { ...mappedActionCreators, ...rest });
            }
            WrappedComponent.displayName = `withGoogleAuth(${Component.displayName || Component.name})`;
            return WrappedComponent;
        };
    }
}
exports.default = withGoogleAuth;
