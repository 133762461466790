"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const lp_components_1 = require("lp-components");
const react_1 = require("react");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const utils_1 = require("utils");
function ResendApplicationForm({ handleSubmit, submitting, data, initialize, }) {
    const [confirmPatientConsent, setConfirmPatientConsent] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        initialize({
            primaryPhone: data?.primaryPhone,
            locationId: data?.locationId,
            uuid: data?.uuid,
            customerName: data?.customerName,
            applicationStatus: data?.applicationStatus,
        });
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", { className: "resend-app-form", children: [(0, jsx_runtime_1.jsx)("div", { className: "title", children: (0, jsx_runtime_1.jsx)("h4", { children: "Re-Send Application" }) }), (0, jsx_runtime_1.jsxs)("form", { onSubmit: handleSubmit, children: [(0, jsx_runtime_1.jsxs)("div", { className: "row", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: "input", type: "radio", value: "sms", label: "SMS", checked: true }), (0, jsx_runtime_1.jsx)("div", { className: "bold", children: "SMS" }), (0, jsx_runtime_1.jsx)("div", { children: (0, utils_1.formatPhoneNumber)(data?.primaryPhone) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "row", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { className: "confirmation-checkbox", component: "input", type: "checkbox", value: "confirmPatientConsent", onChange: () => {
                                    setConfirmPatientConsent(!confirmPatientConsent);
                                } }), (0, jsx_runtime_1.jsx)("div", { children: "Please confirm that the Patient is willing to receive SMS messages" })] }), (0, jsx_runtime_1.jsx)("div", { className: "submit-button-container", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { submitting: submitting, className: "button-warn-outline", pristine: !confirmPatientConsent, disabled: !confirmPatientConsent, children: "SUBMIT" }) })] })] }));
}
exports.default = (0, redux_1.compose)((0, redux_form_1.reduxForm)({
    form: 'ResendApplication',
})(ResendApplicationForm));
