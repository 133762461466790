"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const formatDateFromISOtoUSdateString = (dateString) => {
    if ((0, lodash_1.isNil)(dateString) || dateString === '') {
        return '';
    }
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offset);
    const convertedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return convertedDate;
};
exports.default = formatDateFromISOtoUSdateString;
