"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
const reducerKey = 'legal';
exports.reducerKey = reducerKey;
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({}, initialState);
exports.reducer = reducer;
const selectors = {};
exports.selectors = selectors;
