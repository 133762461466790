"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function toAmount(stringValue) {
    if (typeof stringValue !== 'string')
        throw new Error('Non-string type in toAmount()');
    const number = stringValue.replace(/[^0-9.]/g, '');
    if (number) {
        return Number(number);
    }
    return 0;
}
exports.default = toAmount;
