"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkLocations = void 0;
/**
 *
 * @name checkLocations
 * @component Home
 * @file src/js/main/merchant-portal/views/Home/Home.tsx
 * @desc This function checks all locations and current location
 *      - needsToSignNewDealTerms - boolean
 *      - isCurrentLocationManagerSigner -  boolean
 *      - signers - array
 * @param locations LocationResponseType
 * @param currentLocation LocationType
 * @returns CheckLocationsRespType
 */
const checkLocations = (locations, currentLocation) => {
    const signerBanner = locations.filter((itm) => {
        return itm.needsToSignNewDealTerms && itm.isCurrentLocationManagerSigner;
    }).length > 0;
    const noSignersBanner = currentLocation?.signers?.length === 0 &&
        currentLocation.needsToSignNewDealTerms === true;
    const nonSignerBanner = !noSignersBanner &&
        currentLocation.needsToSignNewDealTerms &&
        currentLocation.isCurrentLocationManagerSigner === false;
    return {
        signerBanner,
        noSignersBanner,
        nonSignerBanner,
    };
};
exports.checkLocations = checkLocations;
