"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.selectors = exports.reducer = void 0;
const redux_actions_1 = require("redux-actions");
// import { selectorForSlice } from 'lp-redux-utils'
const reducerKey = 'styleguide';
exports.reducerKey = reducerKey;
// const slice = 'root.styleguide'
const initialState = {};
const reducer = (0, redux_actions_1.handleActions)({}, initialState);
exports.reducer = reducer;
// const select = selectorForSlice(slice)
const selectors = {};
exports.selectors = selectors;
