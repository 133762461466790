"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.api = void 0;
const react_1 = require("@reduxjs/toolkit/query/react");
const config_1 = require("config");
const utils_1 = require("utils");
const baseQuery = (0, react_1.fetchBaseQuery)({
    baseUrl: config_1.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
        headers.set('Authorization', process.env.NODE_ENV === 'test'
            ? ''
            : `Bearer ${JSON.parse((0, utils_1.getCookie)('portalUser')).credential}`);
    },
});
const baseQueryWithCamelize = (0, utils_1.camelizeResponse)(baseQuery);
exports.api = (0, react_1.createApi)({
    reducerPath: 'internalPortalApi',
    baseQuery: baseQueryWithCamelize,
    endpoints: () => ({}),
});
