"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCookieValidMiddleware = void 0;
const utils_1 = require("utils");
const isCookieValidMiddleware = () => (next) => (action) => {
    if (action &&
        action.type &&
        action.type.includes('internalPortalApi') &&
        process.env.NODE_ENV !== 'test') {
        const cookie = (0, utils_1.getCookie)('portalUser');
        if (!cookie) {
            return next(action);
        }
        const expiration = JSON.parse(cookie).expiration;
        if (expiration < Date.now()) {
            document.cookie =
                'portalUser' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
    }
    return next(action);
};
exports.isCookieValidMiddleware = isCookieValidMiddleware;
