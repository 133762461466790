"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReconciliationReport = exports.fetchApplicationActivity = exports.fetchTransactions = exports.fetchAllLocationsForManager = exports.searchForAccounts = exports.prequalifyWithNextLender = exports.searchForPractices = exports.requestPrequalification = exports.patchApplicant = exports.getCreditApplicationsByToken = exports.getCreditApplicationsByStatus = exports.newCreditApplication = exports.applyAfterPrequalification = void 0;
const lp_redux_api_1 = require("lp-redux-api");
const Fixtures = __importStar(require("fixtures"));
exports.applyAfterPrequalification = (0, lp_redux_api_1.createStubRequest)('APPLY_AFTER_PREQUALIFICATION', (application) => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: accepting pre-qualification and applyung for credit with data: ', JSON.stringify(application, null, 2));
    return Fixtures.applicationApproval;
}, { delay: 1000 });
exports.newCreditApplication = (0, lp_redux_api_1.createStubRequest)('NEW_CREDIT_APPLICATION', (locationId, language = 'en') => {
    // eslint-disable-next-line no-console
    console.log(`[NOTE]: requesting new credit application for location ID: ${locationId} and language: ${language}`);
    return Fixtures.newCreditApplication;
}, { delay: 1000 });
exports.getCreditApplicationsByStatus = (0, lp_redux_api_1.createStubRequest)('GET_CREDIT_APPLICATIONS_BY_STATUS', (applicationSearchCriteria, status) => {
    // eslint-disable-next-line no-console
    console.log(`[NOTE]: finding existing application with criteria: ${applicationSearchCriteria} for status ${status}`);
    return Fixtures.prequalifiedCreditApplications;
});
exports.getCreditApplicationsByToken = (0, lp_redux_api_1.createStubRequest)('GET_CREDIT_APPLICATION_BY_TOKEN', (applicationSearchCriteria, token) => {
    // eslint-disable-next-line no-console
    console.log(`[NOTE]: finding existing application with criteria: ${applicationSearchCriteria} for token ${token}`);
    return Fixtures.existingCreditApplication;
});
exports.patchApplicant = (0, lp_redux_api_1.createStubRequest)('PATCH_APPLICANT', (applicationId, updatedApplicant) => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: patching existing applicant with data: ', updatedApplicant, ', for application: ', applicationId);
    return Fixtures.existingCreditApplication;
});
exports.requestPrequalification = (0, lp_redux_api_1.createStubRequest)('REQUEST_PREQUALIFICATION', (prequalificationData) => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: requesting pre-qualification with data: ', JSON.stringify(prequalificationData, null, 2));
    return Fixtures.prequalificationSuccess;
}, { delay: 1000 });
exports.searchForPractices = (0, lp_redux_api_1.createStubRequest)('PRACTICE_DATA', () => {
    // eslint-disable-next-line no-console
    console.log(`[NOTE]: requesting practice Data`);
    return Fixtures.practiceData;
}, { delay: 1000 });
exports.prequalifyWithNextLender = (0, lp_redux_api_1.createStubRequest)('PREQUALIFY_WITH_NEXT_LENDER', (applicationId, prequalificationData) => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: requesting next prequalification with application id: ', applicationId, ', with data: ', JSON.stringify(prequalificationData, null, 2));
    return Fixtures.prequalificationSuccess;
}, { delay: 1000 });
exports.searchForAccounts = (0, lp_redux_api_1.createStubRequest)('ACCOUNT_DATA', () => {
    // eslint-disable-next-line no-console
    console.log(`[NOTE]: requesting account Data`);
    return Fixtures.accountData;
}, { delay: 1000 });
exports.fetchAllLocationsForManager = (0, lp_redux_api_1.createStubRequest)('FETCH_ALL_LOCATIONS_FOR_MANAGER', () => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: requesting all location data for manager');
    return Fixtures.allLocations;
}, { delay: 1000 });
exports.fetchTransactions = (0, lp_redux_api_1.createStubRequest)('FETCH_ALL_TRANSACTIONS', () => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: requesting all transactions');
    const transactionData = Array(10).fill(Fixtures.transaction);
    return transactionData;
}, { delay: 1000 });
exports.fetchApplicationActivity = (0, lp_redux_api_1.createStubRequest)('FETCH_ALL_APPLICATION_ACTIVITY', () => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: requesting all Application Activity');
    const transactionData = Array(10).fill(Fixtures.activity);
    return transactionData;
}, { delay: 1000 });
exports.getReconciliationReport = (0, lp_redux_api_1.createStubRequest)('GET_RECONCILIATION_REPORT', (reconciliationReportRequest) => {
    // eslint-disable-next-line no-console
    console.log('[NOTE]: requesting reconciliation report with: ', JSON.stringify(reconciliationReportRequest, null, 2));
    return Fixtures.reconciliationReport;
}, { delay: 1000 });
