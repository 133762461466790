"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const redux_form_1 = require("redux-form");
function ComponentWithValue({ input, comps, }) {
    return comps(input.value);
}
function FormSpy({ children, ...props }) {
    return (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: ComponentWithValue, comps: children, ...props });
}
exports.default = FormSpy;
