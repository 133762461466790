"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.postProveVerifyApplicantData = exports.getProvePreFill = exports.getProveTransactionStatus = exports.postProveAuthLinkClicked = exports.postProveSendAuthLink = exports.newCreditApplication = exports.getProveAvail = void 0;
const config_1 = require("config");
const utils_1 = require("utils");
// Get - ProveAvail
const getProveAvail = async () => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/available`, {
        headers: { 'Hyperdx-Session-Info': cookie },
    });
};
exports.getProveAvail = getProveAvail;
const newCreditApplication = async (location_id, language) => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    const queryParams = location_id
        ? `?location_id=${location_id}&language=${language}`
        : `?language=${language}`;
    return await fetch(`${config_1.REACT_APP_API_URL}consumer_applications/v1/prequalifications/new?${queryParams}`, { headers: { 'Hyperdx-Session-Info': cookie } });
};
exports.newCreditApplication = newCreditApplication;
// Post - postProveSendAuthLink
const postProveSendAuthLink = async ({ phone, ssn_last_4, credit_application_id, location_id, }) => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/send_auth_link`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Hyperdx-Session-Info': cookie,
        },
        body: JSON.stringify({
            phone_number: phone,
            ssn_last_4,
            credit_application_id,
            location_id,
        }),
    });
};
exports.postProveSendAuthLink = postProveSendAuthLink;
// Post - postProveAuthLinkClicked
const postProveAuthLinkClicked = async ({ vfp }) => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/auth_link_clicked`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Hyperdx-Session-Info': cookie,
        },
        body: JSON.stringify({
            vfp,
        }),
    });
};
exports.postProveAuthLinkClicked = postProveAuthLinkClicked;
// Get - Status
const getProveTransactionStatus = async (sessionJson) => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    if (Object.keys(sessionJson).length > 0) {
        return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/transaction_status?phone_number=${sessionJson.phone_number}&ssn_last_4=${sessionJson.ssn_last_4}&session_id=${sessionJson.session_id}`, { headers: { 'Hyperdx-Session-Info': cookie } });
    }
};
exports.getProveTransactionStatus = getProveTransactionStatus;
// Get - PreFill
const getProvePreFill = async (sessionJson) => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    if (Object.keys(sessionJson).length > 0) {
        return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/pre_fill?phone_number=${sessionJson.phone_number}&ssn_last_4=${sessionJson.ssn_last_4}&session_id=${sessionJson.session_id}`, { headers: { 'Hyperdx-Session-Info': cookie } });
    }
};
exports.getProvePreFill = getProvePreFill;
// Post - postProveVerifyApplicantData
const postProveVerifyApplicantData = async ({ session_id, pre_fill_data, }) => {
    const cookie = (0, utils_1.getCookie)('__rum_sid');
    return await fetch(`${config_1.REACT_APP_API_URL}identity/v1/prove/verify_applicant_data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Hyperdx-Session-Info': cookie,
        },
        body: JSON.stringify({
            session_id,
            individual: {
                ...pre_fill_data,
            },
        }),
    });
};
exports.postProveVerifyApplicantData = postProveVerifyApplicantData;
