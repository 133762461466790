"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.comparePayFreq = exports.checkPayFreq = void 0;
const date_fns_1 = require("date-fns");
/**
 * @name checkPayFreq
 * @param freq string
 * @param dateString Date
 * @returns string | undefined
 */
const checkPayFreq = (freq, dateString) => {
    const dateFromToday = (0, date_fns_1.formatDistanceToNowStrict)(dateString, {
        unit: 'day',
    })
        .split(' ')
        .at(0);
    if (freq === 'W' && Number(dateFromToday) <= 7 === false)
        return 'Date is past 7 days';
    if (freq === 'B' && Number(dateFromToday) <= 14 === false)
        return 'Date is past 14 days';
    if (freq === 'M' && Number(dateFromToday) <= 31 === false)
        return 'Date is past 31 days';
};
exports.checkPayFreq = checkPayFreq;
/**
 * @name comparePayFreq
 * @param dateLast string
 * @param dateNext string
 * @returns string
 */
const comparePayFreq = (dateLast, dateNext) => {
    // Parse Dates
    const payLastObj = (0, date_fns_1.parse)(dateLast, 'MM/dd/yyyy', new Date());
    const payNextObj = (0, date_fns_1.parse)(dateNext, 'MM/dd/yyyy', new Date());
    // Cannot be before incomePayLast
    if ((0, date_fns_1.isBefore)(payNextObj, payLastObj)) {
        return 'Comes before last pay date ';
    }
    const dateFromToday = (0, date_fns_1.formatDistanceToNowStrict)(payNextObj, {
        unit: 'day',
    })
        .split(' ')
        .at(0);
    // must be within the next 28 days from today
    if (Number(dateFromToday) <= 28 === false)
        return 'Must be within the next 28 days from today';
    const dateDiff = (0, date_fns_1.formatDistanceStrict)(payLastObj, payNextObj, {
        unit: 'day',
    })
        .split(' ')
        .at(0);
    // incomePayLast and incomePayNext cannot be greater than 16 days apart
    if (Number(dateDiff) <= 16 === false)
        return 'Cannot be greater than 16 days apart';
    // incomePayLast and incomePayNext cannot be less than 10 days apart
    if (Number(dateDiff) >= 10 === false)
        return 'Cannot be less than 10 days apart';
};
exports.comparePayFreq = comparePayFreq;
