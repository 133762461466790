"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const forms_1 = require("../../forms");
const UploadForm = ({ displayInfo, noDocsNeeded, uploadCallback, }) => {
    return ((0, jsx_runtime_1.jsx)("div", { className: "doc-upload-container", children: displayInfo && !!displayInfo.length && noDocsNeeded === false && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: displayInfo.map((store, index) => ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: ((store.bankingChanged && store.needsBankingFileUpload) ||
                    (store.entityChanged && store.needsEntityFileUpload)) && ((0, jsx_runtime_1.jsx)("div", { className: "location-container", children: (0, jsx_runtime_1.jsx)(forms_1.StoreDocUploadForm, { store: store, uploadCallback: uploadCallback }) }, index)) }))) })) }));
};
exports.default = UploadForm;
