"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const config_1 = require("config");
function apiErrorToErrorMessage(errorCode, message) {
    if ((0, lodash_1.isEmpty)(errorCode))
        return config_1.API_ERROR_MAP.UNKNOWN_ERROR;
    const errorMessage = config_1.API_ERROR_MAP[errorCode] ??
        message ??
        config_1.API_ERROR_MAP.UNKNOWN_ERROR;
    return `${errorMessage}`;
}
exports.default = apiErrorToErrorMessage;
