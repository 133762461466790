"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const config_1 = require("config");
async function getApplicationBuildID() {
    if (!(0, config_1.isProduction)() || config_1.REACT_APP_BUILDID_FETCH_INTERVAL === 0) {
        return Promise.resolve('DEFAULT APPLICATION VERSION');
    }
    return await fetch(`${config_1.APPLICATION_ROOT_URL}/buildid`);
}
exports.default = getApplicationBuildID;
