"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
const date_fns_tz_1 = require("date-fns-tz");
const config_1 = require("config");
function checkIfESTTimeisPast10Pm(date) {
    const todaysDate = new Date();
    const currentESTDate = (0, date_fns_tz_1.utcToZonedTime)(todaysDate, config_1.EST_TIMEZONE);
    const estDate = (0, date_fns_tz_1.utcToZonedTime)(new Date(date), config_1.EST_TIMEZONE);
    const sameDay = (0, date_fns_1.isSameDay)(estDate, currentESTDate);
    const estHours = (0, date_fns_1.getHours)(estDate);
    return estHours <= 22 && sameDay;
}
exports.default = checkIfESTTimeisPast10Pm;
