"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_redux_1 = require("react-redux");
const redux_1 = require("redux");
const components_1 = require("components");
const merchant_portal_components_1 = require("merchant-portal-components");
const utils_1 = require("utils");
const reducer_1 = require("../reducer");
function AuthorizedButton({ children, disabledToolTip, currentPermissions, invalid = false, requiredPermission, ...rest }) {
    const unauthorized = (0, utils_1.operationNotPermitted)(currentPermissions, requiredPermission) || invalid;
    const message = invalid && disabledToolTip ? disabledToolTip : null;
    return ((0, jsx_runtime_1.jsx)(merchant_portal_components_1.AuthorizationTooltip, { displayed: unauthorized, message: message, children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", invalid: unauthorized, ...rest, children: children }) }));
}
function mapStateToProps(state) {
    return {
        currentPermissions: reducer_1.selectors.currentPermissions(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(AuthorizedButton);
