"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const config_1 = require("config");
function mapTransactionTypeToProductType(transactionType) {
    if ((0, lodash_1.isNil)(transactionType))
        return false;
    return config_1.TRANSACTION_TYPE_TO_PRODUCT_TYPE_MAP[transactionType];
}
exports.default = mapTransactionTypeToProductType;
