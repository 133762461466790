"use strict";
//This file maps directly to _colors.scss
Object.defineProperty(exports, "__esModule", { value: true });
exports.INLINE_COLORS = void 0;
exports.INLINE_COLORS = {
    //BLACK
    blackBase: '#00000e',
    blackLight: '#707077',
    //WHITE
    whiteBase: '#ffffff',
    //GREY
    greyBase: '#c6c5c5',
    greyLight: '#eae9e9',
    //LIGHT GREY
    lightgreyBase: '#e5e5e4',
    lightgreyLight: '#f5f5f5',
    lightgreyLighter: '#fcfcfc',
    //DARK GREY
    darkgreyBase: '#6d6d6d',
    darkgreyLight: '#adadad',
    darkgreyLighter: '#c8c8c8',
    //RED
    redBase: '#bf2536',
    redLight: '#fdf6f7',
    //BLUE
    lightblueBase: '#f2fbff',
    blueBase: '#1d6c8b',
    //GREEN
    greenBase: '#43a03c',
    greenLight: '#ecf6ec',
    //PURPLE
    purpleBase: '#792e9e',
};
