"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_toastify_1 = require("react-toastify");
function toastError(message) {
    react_toastify_1.toast.error(message, {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        className: 'toast-base toast-error',
    });
}
exports.default = toastError;
