"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const utils_1 = require("utils");
const TooZoom = () => {
    const MAX_ZOOM = 175; // percent screen zoom
    // State
    const [showMsg, setShowMsg] = (0, react_1.useState)(false);
    const handleWindowResize = () => {
        checkZoom();
    };
    // Left in case we want to allow user to disable
    const handleDontShowMsg = () => {
        document.cookie = 'showZoomMsg=false';
        setShowMsg(false);
        window.removeEventListener('resize', handleWindowResize);
    };
    const getZoom = () => {
        const highDPI = isHighDPI();
        if (highDPI) {
            return Math.round(window.devicePixelRatio * 50);
        }
        else {
            return Math.round(window.devicePixelRatio * 100);
        }
    };
    const checkZoom = () => {
        const zoomCookie = (0, utils_1.getCookie)('showZoomMsg');
        if (zoomCookie === 'false')
            return;
        if (!window.matchMedia('(pointer: coarse)').matches) {
            const zoomLevel = getZoom();
            if (zoomLevel >= MAX_ZOOM) {
                setShowMsg(true);
            }
            else {
                setShowMsg(false);
            }
        }
    };
    const isHighDPI = () => {
        const dpiMap = (0, utils_1.dpiDetector)();
        const hasDPIArr = Object.values(dpiMap.screens).filter((itm) => {
            return itm;
        });
        if (hasDPIArr.length > 0 && window.visualViewport.width >= 1000) {
            return true;
        }
        else {
            return false;
        }
    };
    const isMac = (0, react_1.useMemo)(() => {
        // Currently Typescript doesn't ship with type declarations for the experimental navigator.userAgentData which is why we have to cast it as any here
        // StackOverflow thread: https://stackoverflow.com/questions/71392342/property-useragentdata-does-not-exist-on-type-navigator
        if (navigator.userAgentData &&
            navigator.userAgentData.platform) {
            return navigator.userAgentData.platform === 'macOS';
        }
        else {
            if ('platform' in navigator) {
                1;
                if (navigator.platform) {
                    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
                }
            }
            else {
                return false;
            }
        }
    }, [showMsg]);
    (0, react_1.useEffect)(() => {
        const zoomCookie = (0, utils_1.getCookie)('showZoomMsg');
        if (zoomCookie !== 'false') {
            checkZoom();
            window.addEventListener('resize', handleWindowResize);
        }
        return () => {
            if (zoomCookie !== 'false') {
                window.removeEventListener('resize', handleWindowResize);
            }
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [showMsg ? ((0, jsx_runtime_1.jsx)("div", { className: "too-zoom-shell", children: (0, jsx_runtime_1.jsxs)("div", { className: "too-zoom", children: [(0, jsx_runtime_1.jsx)("h2", { children: "You are too zoomed in!" }), (0, jsx_runtime_1.jsxs)("p", { children: ["Reset the screen by pressing \"", isMac ? 'cmd' : 'ctrl', " + 0\""] }), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("br", {}), (0, jsx_runtime_1.jsx)("button", { type: "button", onClick: handleDontShowMsg, children: "Click here to disable this message" })] }) })) : null, showMsg ? (0, jsx_runtime_1.jsx)("div", { className: "too-zoom-bg" }) : null] }));
};
exports.default = TooZoom;
