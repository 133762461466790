"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
const config_1 = require("config");
function getReportDates({ dateFilterType, fromDate = 0, toDate = 0, }) {
    const todaysDate = new Date();
    const startDate = dateFilterType === config_1.DATE_SELECTION.DATE_RANGE
        ? fromDate
        : startDateFromFilterType(todaysDate, dateFilterType);
    const endDate = dateFilterType === config_1.DATE_SELECTION.DATE_RANGE
        ? toDate
        : endDateFromFilterType(todaysDate, dateFilterType);
    return {
        startDate: (0, date_fns_1.formatISO)(startDate, { representation: 'date' }),
        endDate: (0, date_fns_1.formatISO)(endDate, { representation: 'date' }),
    };
}
exports.default = getReportDates;
function endDateFromFilterType(todaysDate, dateFilterType) {
    switch (dateFilterType) {
        case config_1.DATE_SELECTION.PRIOR_MONTH:
            return (0, date_fns_1.endOfMonth)((0, date_fns_1.subMonths)(todaysDate, 1));
        default:
            return todaysDate;
    }
}
function startDateFromFilterType(todaysDate, dateFilterType) {
    switch (dateFilterType) {
        case config_1.DATE_SELECTION.MONTH_TO_DATE:
            return (0, date_fns_1.startOfMonth)(todaysDate);
        case config_1.DATE_SELECTION.PRIOR_MONTH:
            return (0, date_fns_1.startOfMonth)((0, date_fns_1.subMonths)(todaysDate, 1));
        case config_1.DATE_SELECTION.YEAR_TO_DATE:
            return (0, date_fns_1.startOfYear)(todaysDate);
        default:
            throw new Error('unknown date filter type in getReportDates');
    }
}
