"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const config_1 = require("config");
const parseUSDayMonthYearDate = (dateString) => {
    if ((0, lodash_1.isEmpty)(dateString))
        return;
    if (dateString.length !== config_1.DATE_STRING_LENGTH)
        return;
    if (dateString.includes('-')) {
        return (0, date_fns_1.parse)(dateString, 'yyyy-MM-dd', new Date());
    }
    return (0, date_fns_1.parse)(dateString, 'MM/dd/yyyy', new Date());
};
exports.default = parseUSDayMonthYearDate;
