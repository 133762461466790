"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const config_1 = require("config");
const SHOW_STYLEGUIDE = !(0, config_1.isProduction)() || process.env.REACT_APP_DISPLAY_STYLEGUIDE;
function Layout({ children }) {
    if (!SHOW_STYLEGUIDE)
        return null;
    return (0, jsx_runtime_1.jsx)("div", { children: children });
}
exports.default = Layout;
