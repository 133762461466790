"use strict";
/**
 * @name windowOpenAndPrint
 * @type Function
 * @param {string|URL|undefined} pdfWindowURL url to load the PDF into
 * @param {boolean|undefined} sameWindow whether to open the PDF in the same window
 * @param {boolean|undefined} print whether to print the PDF
 * @return
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.windowOpenAndPrint = void 0;
const windowOpenAndPrint = (pdfWindowURL, sameWindow = false, print = true) => {
    const _target = sameWindow ? '_self' : isIpad() ? '_top' : '_blank';
    const pdfWindow = window.open(pdfWindowURL, _target, 'noopener');
    print && pdfWindow && pdfWindow.print();
};
exports.windowOpenAndPrint = windowOpenAndPrint;
/**
 * @name isIpad
 * @description Checks for the latest way to detect iPads ref: https://stackoverflow.com/a/64559209
 *              Security issues with target='_blank' https://www.js-craft.io/blog/window-open-and-target_blank-have-a-security-vulnerability/
 * @returns {boolean}
 */
const isIpad = () => (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
    navigator.platform === 'iPad';
