"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
function ExternalLinkButton({ children, className, href, invalid, ...rest }, ref) {
    // Ensure that all web addresses begin with a protocol (the expectation). If
    // they don't, assume the current protocol (typically https) by prepending
    // "//" to the URL.
    const normalizedUrl = href.startsWith('http') ? href : `//${href}`;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("a", { className: "external-link", target: "_blank", rel: "noopener noreferrer", href: normalizedUrl, ...rest, children: (0, jsx_runtime_1.jsx)(components_1.Button, { type: "button", className: `${className} external-link-button`, invalid: invalid, ref: ref, children: children }) }), (0, jsx_runtime_1.jsx)("span", { hidden: true, children: "Opens in a new window" })] }));
}
exports.default = (0, react_1.forwardRef)(ExternalLinkButton);
