"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usStates = void 0;
exports.usStates = [
    { key: 'ALABAMA', value: 'AL' },
    { key: 'ALASKA', value: 'AK' },
    { key: 'ARIZONA', value: 'AZ' },
    { key: 'ARKANSAS', value: 'AR' },
    { key: 'CALIFORNIA', value: 'CA' },
    { key: 'COLORADO', value: 'CO' },
    { key: 'CONNECTICUT', value: 'CT' },
    { key: 'DELAWARE', value: 'DE' },
    { key: 'DISTRICT OF COLUMBIA', value: 'DC' },
    { key: 'FLORIDA', value: 'FL' },
    { key: 'GEORGIA', value: 'GA' },
    { key: 'HAWAII', value: 'HI' },
    { key: 'IDAHO', value: 'ID' },
    { key: 'ILLINOIS', value: 'IL' },
    { key: 'INDIANA', value: 'IN' },
    { key: 'IOWA', value: 'IA' },
    { key: 'KANSAS', value: 'KS' },
    { key: 'KENTUCKY', value: 'KY' },
    { key: 'LOUISIANA', value: 'LA' },
    { key: 'MAINE', value: 'ME' },
    { key: 'MARYLAND', value: 'MD' },
    { key: 'MASSACHUSETTS', value: 'MA' },
    { key: 'MICHIGAN', value: 'MI' },
    { key: 'MINNESOTA', value: 'MN' },
    { key: 'MISSISSIPPI', value: 'MS' },
    { key: 'MISSOURI', value: 'MO' },
    { key: 'MONTANA', value: 'MT' },
    { key: 'NEBRASKA', value: 'NE' },
    { key: 'NEVADA', value: 'NV' },
    { key: 'NEW HAMPSHIRE', value: 'NH' },
    { key: 'NEW JERSEY', value: 'NJ' },
    { key: 'NEW MEXICO', value: 'NM' },
    { key: 'NEW YORK', value: 'NY' },
    { key: 'NORTH CAROLINA', value: 'NC' },
    { key: 'NORTH DAKOTA', value: 'ND' },
    { key: 'OHIO', value: 'OH' },
    { key: 'OKLAHOMA', value: 'OK' },
    { key: 'OREGON', value: 'OR' },
    { key: 'PENNSYLVANIA', value: 'PA' },
    { key: 'RHODE ISLAND', value: 'RI' },
    { key: 'SOUTH CAROLINA', value: 'SC' },
    { key: 'SOUTH DAKOTA', value: 'SD' },
    { key: 'TENNESSEE', value: 'TN' },
    { key: 'TEXAS', value: 'TX' },
    { key: 'UTAH', value: 'UT' },
    { key: 'VERMONT', value: 'VT' },
    { key: 'VIRGINIA', value: 'VA' },
    { key: 'WASHINGTON', value: 'WA' },
    { key: 'WEST VIRGINIA', value: 'WV' },
    { key: 'WISCONSIN', value: 'WI' },
    { key: 'WYOMING', value: 'WY' },
];
