"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducerKey = exports.reducer = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const global_search_1 = require("./global-search");
const reducerKey = 'internalPortal';
exports.reducerKey = reducerKey;
const reducer = (0, toolkit_1.combineReducers)({
    ['globalSearch']: global_search_1.globalSearchReducer,
});
exports.reducer = reducer;
