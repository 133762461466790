"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.refundLoan = exports.cancelLoan = exports.createNewCreditApplication = exports.adjustLoan = exports.updateManager = exports.getManagerById = exports.newManager = exports.getManagers = exports.getLocationNotifications = exports.getReconciliationReport = exports.getCreditAvailableReport = exports.getSalesReport = exports.getApplicationsReport = exports.fetchApplicationActivity = exports.fetchTransactions = exports.fetchLocationPricingTiers = exports.fetchPlansByLocationId = exports.salesRequest = exports.refundRequest = exports.voidSale = exports.fetchLocationDetails = exports.fetchAllLocationsForManager = exports.applyForCredit = exports.newMerchantCreditApplication = exports.searchForAccounts = exports.validateSSN = exports.signConsumerReceipt = exports.submitFullApplication = exports.prequalifyCreditApplication = exports.patchApplicant = exports.prequalifyWithNextLender = exports.requestPrequalification = exports.searchForPractices = exports.newCreditApplication = exports.applyAfterPrequalification = exports.getReceiptPDF = exports.getReceipt = exports.getCreditApplicationByTokenValidate = exports.getCreditApplicationByToken = exports.getCreditApplicationsByStatus = exports.signNewDealTerms = exports.getNewDealTerms = exports.updateProposedEnrollmentFileChanges = exports.updateProposedEnrollmentDataChanges = exports.createProposedEnrollmentDataChanges = exports.getBankName = exports.getProposedEnrollmentDataChanges = exports.uploadDocument = exports.addAuthorizedSigner = exports.getInternalPortalHome = void 0;
exports.getAdsTerms = exports.affContractStatus = exports.ugaContractStatus = exports.getReceiptOptions = exports.createCalculatorPDF = exports.getAccountVerification = exports.requestAccountVerification = exports.getCreditApplicationIdentityVerification = exports.getLenderSettings = exports.createCreditApplicationIdentityVerification = exports.createPushApplication = exports.getSalesReceiptSigned = exports.getSalesReceipt = exports.createExternalCreditInstrumentReference = exports.sendExternalReferencesCommunication = exports.createReceipt = exports.sendReceipt = exports.signReceiptV2 = exports.signReceipt = exports.getLenderTerms = exports.updateLocationPlans = exports.getPracticeLocations = void 0;
//@ts-nocheck
const config_1 = require("config");
const productionApiActions = __importStar(require("./productionApiActions"));
const testApiActions = __importStar(require("./testApiActions"));
const actions = (0, config_1.usingTestApi)() ? testApiActions : productionApiActions;
exports.getInternalPortalHome = actions.getInternalPortalHome;
exports.addAuthorizedSigner = actions.addAuthorizedSigner;
exports.uploadDocument = actions.uploadDocument;
exports.getProposedEnrollmentDataChanges = actions.getProposedEnrollmentDataChanges;
exports.getBankName = actions.getBankName;
exports.createProposedEnrollmentDataChanges = actions.createProposedEnrollmentDataChanges;
exports.updateProposedEnrollmentDataChanges = actions.updateProposedEnrollmentDataChanges;
exports.updateProposedEnrollmentFileChanges = actions.updateProposedEnrollmentFileChanges;
exports.getNewDealTerms = actions.getNewDealTerms;
exports.signNewDealTerms = actions.signNewDealTerms;
exports.getCreditApplicationsByStatus = actions.getCreditApplicationsByStatus;
exports.getCreditApplicationByToken = actions.getCreditApplicationByToken;
exports.getCreditApplicationByTokenValidate = actions.getCreditApplicationByTokenValidate;
exports.getReceipt = actions.getReceipt;
exports.getReceiptPDF = actions.getReceiptPDF;
exports.applyAfterPrequalification = actions.applyAfterPrequalification;
exports.newCreditApplication = actions.newCreditApplication;
exports.searchForPractices = actions.searchForPractices;
exports.requestPrequalification = actions.requestPrequalification;
exports.prequalifyWithNextLender = actions.prequalifyWithNextLender;
exports.patchApplicant = actions.patchApplicant;
exports.prequalifyCreditApplication = actions.prequalifyCreditApplication;
exports.submitFullApplication = actions.submitFullApplication;
exports.signConsumerReceipt = actions.signConsumerReceipt;
exports.validateSSN = actions.validateSSN;
//Merchant portal api actions
exports.searchForAccounts = actions.searchForAccounts;
exports.newMerchantCreditApplication = actions.newMerchantCreditApplication;
exports.applyForCredit = actions.applyForCredit;
exports.fetchAllLocationsForManager = actions.fetchAllLocationsForManager;
exports.fetchLocationDetails = actions.fetchLocationDetails;
exports.voidSale = actions.voidSale;
exports.refundRequest = actions.refundRequest;
exports.salesRequest = actions.salesRequest;
exports.fetchPlansByLocationId = actions.fetchPlansByLocationId;
exports.fetchLocationPricingTiers = actions.fetchLocationPricingTiers;
exports.fetchTransactions = actions.fetchTransactions;
exports.fetchApplicationActivity = actions.fetchApplicationActivity;
exports.getApplicationsReport = actions.getApplicationsReport;
exports.getSalesReport = actions.getSalesReport;
exports.getCreditAvailableReport = actions.getCreditAvailableReport;
exports.getReconciliationReport = actions.getReconciliationReport;
exports.getLocationNotifications = actions.getLocationNotifications;
exports.getManagers = actions.getManagers;
exports.newManager = actions.newManager;
exports.getManagerById = actions.getManagerById;
exports.updateManager = actions.updateManager;
exports.adjustLoan = actions.adjustLoan;
exports.createNewCreditApplication = actions.createNewCreditApplication;
exports.cancelLoan = actions.cancelLoan;
exports.refundLoan = actions.refundLoan;
exports.getPracticeLocations = actions.getPracticeLocations;
exports.updateLocationPlans = actions.updateLocationPlans;
exports.getLenderTerms = actions.getLenderTerms;
exports.signReceipt = actions.signReceipt;
exports.signReceiptV2 = actions.signReceiptV2;
exports.sendReceipt = actions.sendReceipt;
exports.createReceipt = actions.createReceipt;
exports.sendExternalReferencesCommunication = actions.sendExternalReferencesCommunication;
exports.createExternalCreditInstrumentReference = actions.createExternalCreditInstrumentReference;
exports.getSalesReceipt = actions.getSalesReceipt;
exports.getSalesReceiptSigned = actions.getSalesReceiptSigned;
exports.createPushApplication = actions.createPushApplication;
exports.createCreditApplicationIdentityVerification = actions.createCreditApplicationIdentityVerification;
exports.getLenderSettings = actions.getLenderSettings;
exports.getCreditApplicationIdentityVerification = actions.getCreditApplicationIdentityVerification;
exports.requestAccountVerification = actions.requestAccountVerification;
exports.getAccountVerification = actions.getAccountVerification;
exports.createCalculatorPDF = actions.createCalculatorPDF;
exports.getReceiptOptions = actions.getReceiptOptions;
exports.ugaContractStatus = actions.ugaContractStatus;
exports.affContractStatus = actions.affContractStatus;
exports.getAdsTerms = actions.getAdsTerms;
