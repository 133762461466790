"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
function PlanOptionsRow({ data, onChange }) {
    const plan = data;
    const handleChange = (plan) => {
        onChange(plan);
    };
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)("td", { children: [(0, jsx_runtime_1.jsx)("div", { className: "plan-selection-radio", children: (0, jsx_runtime_1.jsx)("input", { type: "radio", className: "clickable", onChange: () => handleChange(plan), name: "planOptions" }, plan.name) }), (0, jsx_runtime_1.jsxs)("div", { className: "plan-selection-description", children: [(0, jsx_runtime_1.jsxs)("span", { className: "plan-title", children: [plan.name, " -"] }), "\u00A0", (0, jsx_runtime_1.jsxs)("span", { className: "plan-description", children: [(0, jsx_runtime_1.jsxs)("b", { children: [plan.merchantDiscountRate.toFixed(2), "% "] }), " Merchant Fee"] })] })] }) }));
}
exports.default = (0, react_1.memo)(PlanOptionsRow);
