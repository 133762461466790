"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const lp_components_1 = require("lp-components");
const lp_form_1 = require("lp-form");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const react_router_dom_1 = require("react-router-dom");
const redux_1 = require("redux");
const redux_form_1 = require("redux-form");
const components_1 = require("components");
const config_1 = require("config");
const utils_1 = require("utils");
const reducer_1 = require("../../reducer");
function ReportsForm({ allLocations, change, currentPermissions, dateFilterType, handleSubmit, submitting, }) {
    const { search } = (0, react_router_dom_1.useLocation)();
    const query = new URLSearchParams(search);
    const queryType = query.get('type') ?? '';
    const initialReportValue = queryType === 'application-tracking' ||
        queryType === 'sales' ||
        queryType === 'bank-reconciliation' ||
        queryType === 'credit-available'
        ? queryType
        : '';
    const REPORT_TYPES = (0, utils_1.getAuthorizedReportTypes)(currentPermissions);
    const [selectedReportType, setSelectedReportType] = (0, react_1.useState)(initialReportValue);
    (0, react_1.useEffect)(() => {
        setSelectedReportType(initialReportValue);
        change('reportType', getReportType(initialReportValue));
    }, [initialReportValue]);
    const getReportType = (reportValue) => {
        const updatedReportType = REPORT_TYPES.find((reportType) => reportType.value === reportValue);
        return updatedReportType;
    };
    const handleSelectedReportType = (newValue) => {
        setSelectedReportType(newValue.value);
    };
    const locationData = (0, react_1.useMemo)(() => (0, utils_1.locationOptionsWithLabels)(allLocations), [allLocations]);
    const populateReadOnlyDateRanges = (0, react_1.useCallback)((_, dateFilterType) => {
        // Read-only date range fields are replaced with actual date ranges if
        // the DATE_RANGE type is selected...
        if (dateFilterType === config_1.DATE_SELECTION.DATE_RANGE)
            return;
        const { startDate, endDate } = (0, utils_1.getReportDates)({ dateFilterType });
        change('readOnlyStartDate', startDate);
        change('readOnlyEndDate', endDate);
    }, []);
    return ((0, jsx_runtime_1.jsx)("form", { noValidate: true, onSubmit: handleSubmit, children: (0, jsx_runtime_1.jsxs)("div", { className: "report-form m-t-30", children: [(0, jsx_runtime_1.jsxs)("div", { className: "date-selection-container", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "dateFilterType", children: "Date:" }), selectedReportType !== 'credit-available' ? ((0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.RadioGroup, name: "dateFilterType", label: false, onChange: populateReadOnlyDateRanges, options: config_1.DATE_FILTER_TYPE, radioInputProps: {
                                className: 'radioInput',
                            } })) : ((0, jsx_runtime_1.jsxs)("div", { className: "date-current-month", children: ["Current Month - ", (0, utils_1.getMonthFromDate)(new Date())] })), (0, jsx_runtime_1.jsx)("div", { className: "date-selection", children: selectedReportType !== 'credit-available' &&
                                (dateFilterType === config_1.DATE_SELECTION.DATE_RANGE ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.DateInput, name: "startDate", label: false, autoComplete: "off" }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.DateInput, name: "endDate", label: false, autoComplete: "off" })] })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.Input, name: "readOnlyStartDate", label: false, disabled: true }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { component: lp_components_1.Input, name: "readOnlyEndDate", label: false, disabled: true })] }))) })] }), (0, jsx_runtime_1.jsxs)("div", { className: "report-criteria-container", children: [(0, jsx_runtime_1.jsx)(redux_form_1.Field, { className: "report-selector", name: "reportType", component: components_1.Select, options: REPORT_TYPES, label: "Report Type:", placeholder: "- Please Choose -", onChange: handleSelectedReportType }), (0, jsx_runtime_1.jsx)(redux_form_1.Field, { name: "locationIds", component: components_1.Select, multiple: true, options: locationData, label: "Select Locations: ", placeholder: "Location", isMulti: true, closeMenuOnSelect: false, hideSelectedOptions: false, allowSelectAll: true })] }), (0, jsx_runtime_1.jsx)("div", { className: "button", children: (0, jsx_runtime_1.jsx)(lp_components_1.SubmitButton, { submitting: submitting, disabled: submitting, className: `button-warn-outline`, children: "RUN REPORT" }) })] }) }));
}
function beforeSubmit(formValues) {
    const { startDate, endDate } = formValues;
    if ((0, lodash_1.isNil)(startDate))
        return formValues;
    if ((0, date_fns_1.isAfter)(startDate, endDate)) {
        throw new redux_form_1.SubmissionError({
            startDate: 'start date must be later than end date',
        });
    }
    return formValues;
}
function mapStateToProps(state) {
    return {
        currentPermissions: reducer_1.selectors.currentPermissions(state),
    };
}
const mapDispatchToProps = {};
exports.default = (0, redux_1.compose)((0, lp_form_1.lpForm)({
    beforeSubmit,
    name: 'reportForm',
    constraints: {
        reportType: { presence: true },
        startDate: { presence: true },
        endDate: { presence: true },
        locationIds: { presence: true },
    },
    submitFilters: { reject: ['readOnlyStartDate', 'readOnlyEndDate'] },
}), (0, redux_form_1.formValues)('dateFilterType'), (0, react_redux_1.connect)(mapStateToProps, mapDispatchToProps))(ReportsForm);
