"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const components_1 = require("components");
function InputTip(props) {
    const [focus, setFocus] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(props.inputType, { onFocus: () => {
                    setFocus(true);
                }, ...props }), (0, jsx_runtime_1.jsx)(components_1.FormSpy, { name: props.input.name, focused: focus, children: (val) => {
                    if ((val || focus) && props.tooltips) {
                        return (0, jsx_runtime_1.jsx)(props.tooltips, {});
                    }
                    return null;
                } })] }));
}
exports.default = InputTip;
